import { FC } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import {
  CreateContent,
  ContentList,
  EditContent,
} from "../pages/ContentHelper";
import { CreateImage, ImageList } from "../pages/Image";
import Dashboard from "../pages/Dashboard";
import Subscription from "../pages/Subscription";
import WordPressPlugin from "../pages/WordPressPlugin";
import { RephraseContent } from "../pages/Rephrase/RephraseContent";
import { SummarizeContent } from "../pages/Summarize/SummarizeContent";

const PrivateRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />

        {/* Pages */}
        <Route path="dashboard" element={<Dashboard />} />

        <Route path="/ai-powered">
          {/* Content routes */}
          <Route path="content">
            <Route path="list" element={<ContentList />} />
            <Route path="create" element={<CreateContent />} />
            <Route path="edit/:id" element={<EditContent />} />
          </Route>

          {/* Image routes */}
          <Route path="image">
            <Route path="create" element={<CreateImage />} />
            <Route path="list" element={<ImageList />} />
          </Route>

          <Route path="rephrase" element={<RephraseContent />} />
          <Route path="summarize" element={<SummarizeContent />} />
        </Route>

        <Route path="subscription" element={<Subscription />} />

        <Route path="wordpress-plugin" element={<WordPressPlugin />} />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/auth" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
