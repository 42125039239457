/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import SelectLanguage from "../SelectLanguage";
import { KTSVG } from "../../../_metronic/helpers";
import {
  getUserPreferences,
  setUserPreferences,
  useAuth,
} from "../../pages/auth";
import { useSetState } from "../../hooks";

export const EditorSettings: FC<any> = ({ closeEditorSettings, style }) => {
  const outClickRef = useRef<any>();

  const { updatePreferences } = useAuth();
  const [preferences, setPreferences] = useSetState(getUserPreferences());

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        outClickRef.current.contains &&
        !outClickRef.current.contains(event.target)
      ) {
        closeEditorSettings();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      updatePreferences();
    };
  }, []);

  useEffect(() => {
    setUserPreferences(preferences);
  }, [preferences]);

  const onLanguageChange = (item: any) => {
    setPreferences({
      default_language: item.value,
    });
  };

  return (
    <div
      ref={outClickRef}
      className="editor-setting-popover position-fixed fw-semibold py-4 px-6 w-220px"
      style={style}
    >
      <div className="d-flex justify-content-between align-items-center mb-6">
        <span className="fs-3">
          <FormattedMessage id="common.settings" />
        </span>
        <a className="cursor-pointer" onClick={closeEditorSettings}>
          <KTSVG path="/media/icons/close.svg" className="svg-icon-2x" />
        </a>
      </div>

      <div className="d-flex align-items-center gap-3 w-100 mt-4">
        <SelectLanguage
          initialValue={preferences.default_language}
          onChange={onLanguageChange}
          menuPlacement="top"
        />
      </div>
    </div>
  );
};
