import { FormattedMessage } from "react-intl";
import { KTSVG } from "../../../../_metronic/helpers";
import { DateTime } from "luxon";

export const createColumns = (
  onDeleteHandler: Function,
  onEditHandler: Function
) => {
  return [
    {
      key: "title",
      dataIndex: "title",
      title: <FormattedMessage id="table.columns.title" />,
      render: (title: string) => {
        return title ? title : <FormattedMessage id="common.untitled" />;
      },
    },
    {
      key: "updated_at",
      dataIndex: "updated_at",
      title: <FormattedMessage id="table.columns.updatedAt" />,
      className: "w-200px",
      render: (updated_at: string) => {
        return DateTime.fromISO(updated_at).toFormat("dd/MM/yyyy, HH:mm");
      },
    },
    {
      key: "action",
      dataIndex: "id",
      align: "right",
      title: <FormattedMessage id="table.columns.actions" />,
      className: "text-end w-100px",
      render: (id: number) => {
        return (
          <div className="d-flex justify-content-end flex-shrink-0 gap-2">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="btn btn-bg-light btn-icon btn-active-color-primary btn-sm me-1"
              onClick={() => {
                onEditHandler(id);
              }}
            >
              <KTSVG path="/media/icons/edit.svg" className="svg-icon-3" />
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="btn btn-bg-light btn-icon btn-active-color-danger btn-sm"
              onClick={() => {
                onDeleteHandler(id);
              }}
            >
              <KTSVG path="/media/icons/delete.svg" className="svg-icon-3" />
            </a>
          </div>
        );
      },
    },
  ];
};
