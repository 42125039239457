import { FC, memo } from "react";
import { ErrorMessage, Field } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

const Step1: FC = () => {
  const intl = useIntl();

  return (
    <div className="w-100">
      <div className="fv-row mb-10">
        <label className="form-label required">
          <FormattedMessage id="image.prompt" />
        </label>

        <Field
          as="textarea"
          name="prompt"
          className="form-control form-control-lg form-control-solid"
          rows={3}
          placeholder={intl.formatMessage({ id: "image.prompt.placeholder" })}
        />
        <div className="text-danger mt-2">
          <ErrorMessage name="prompt" />
        </div>
      </div>
    </div>
  );
};

export default memo(Step1);
