import { _intl } from "../../../../_metronic/layout/MasterInit";
import { aiApi } from "../../../api";
import { swalUtil } from "../../../utils/swalUtil";
import { LOADING_END_EVENT, LOADING_START_EVENT } from "../events";
import { UPSCALE_IMAGE_ICON } from "../Icons";
import { createTuneItem } from "../utils";

export class UpscaleImageTune {
  static get isTune() {
    return true;
  }

  constructor({ api }) {
    this.api = api;
  }

  getCurrentBlock = () => {
    const currentIndex = this.api.blocks.getCurrentBlockIndex();
    const currentBlock = this.api.blocks.getBlockByIndex(currentIndex);

    return [currentBlock, currentIndex];
  };

  isDisabled = () => {
    const [currentBlock] = this.getCurrentBlock();
    return currentBlock.isEmpty;
  };

  onActivateHandler = async () => {
    try {
      this.api.events.emit(LOADING_START_EVENT);
      const [currentBlock] = this.getCurrentBlock();
      const imageBlock = currentBlock.holder.getElementsByClassName(
        "image-tool__image-picture"
      )?.[0];

      if (!imageBlock) {
        return;
      }

      const response = await aiApi.upscaleImage({ image_url: imageBlock.src });

      this.api.blocks.update(currentBlock.id, {
        file: {
          url: response.data.image_url,
        },
      });
      this.api.events.emit(LOADING_END_EVENT);
    } catch (error) {
      this.api.events.emit(LOADING_END_EVENT);
      swalUtil.showErrorMessage(error);
    }
  };

  render() {
    return createTuneItem({
      label: _intl.formatMessage({ id: "editor.image.upscaleImage" }),
      icon: UPSCALE_IMAGE_ICON,
      isDisable: this.isDisabled(),
      onActivate: this.onActivateHandler,
    });
  }
}
