import { useState } from "react";
import { createRoot } from "react-dom/client";

import { KTSVG } from "../../../../_metronic/helpers";
import { _intl } from "../../../../_metronic/layout/MasterInit";
import { ERROR_EVENT } from "../events";
import { CREATE_CONTENT_ICON } from "../Icons";

const View = ({ editorApi }) => {
  const [topic, setTopic] = useState();

  const createContent = async () => {
    try {
      const currentIndex = editorApi.blocks.getCurrentBlockIndex();
      editorApi.blocks.delete(currentIndex);
      editorApi.blocks.insert(
        "socketWriterBlock",
        { socketPayload: { topic } },
        undefined,
        currentIndex
      );
    } catch (error) {
      editorApi.events.emit(ERROR_EVENT);
    }
  };

  return (
    <div className="w-full d-flex border rounded p-4 mb-2 gap-4 align-items-center">
      <textarea
        className="form-control form-control-solid resize-none my-4 disabled"
        placeholder={_intl.formatMessage({
          id: "editor.content.inputPlaceholder",
        })}
        rows={1}
        onChange={(e) => {
          setTopic(e.target.value);
        }}
      />

      <button
        className={`btn btn-sm btn-primary fw-bold w-200px`}
        onClick={createContent}
        disabled={!topic}
      >
        <KTSVG path="/media/icons/text-2.svg" className="svg-icon" />
        {_intl.formatMessage({ id: "editor.content.create" })}
      </button>
    </div>
  );
};

export class CreateContentBlock {
  static get toolbox() {
    return {
      title: _intl.formatMessage({ id: "editor.content.create" }),
      icon: CREATE_CONTENT_ICON,
    };
  }

  constructor({ api }) {
    this.api = api;
  }

  render() {
    const container = document.createElement("div");
    createRoot(container).render(<View editorApi={this.api} />);

    return container;
  }

  save(blockContent) {
    return {
      url: blockContent.value,
    };
  }
}
