import { useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { Modal } from "react-bootstrap";

import { _intl } from "../../../../_metronic/layout/MasterInit";
import { aiApi } from "../../../api";
import { swalUtil } from "../../../utils/swalUtil";
import { SWAP_FACES_ICON } from "../Icons";
import { createTuneItem } from "../utils";
import { KTSVG } from "../../../../_metronic/helpers";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import documentApi from "../../../api/documentApi";
import BaseLoading from "../../BaseLoading";

const ImageUploadModal = ({
  editorApi,
  onModalCloseHandler,
  imageBlock,
  currentBlock,
}) => {
  const inputRef = useRef();
  const selectedImageUrlRef = useRef();

  const [selectedImage, setSelectedImage] = useState(null);
  const [swappedFacesImageUrl, setSwappedFacesImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onUploadClickHandler = () => {
    inputRef.current.click();
  };

  const onImageSelectHandler = (e) => {
    const file = e.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
    }
  };

  const onSwapFacesHandler = async () => {
    try {
      setIsLoading(true);
      if (!selectedImageUrlRef.current) {
        // upload and create swap image url
        const swapImageResp = await documentApi.uploadImage({
          image: selectedImage,
        });
        selectedImageUrlRef.current = swapImageResp.data.image_url;
      }

      // swap faces
      const response = await aiApi.swapFaces({
        base_image: imageBlock.src,
        swap_image: selectedImageUrlRef.current,
      });
      setSwappedFacesImageUrl(response.data.image_url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      swalUtil.showErrorMessage(error);
    }
  };

  const onApplyHandler = () => {
    editorApi.blocks.update(currentBlock.id, {
      file: {
        url: swappedFacesImageUrl,
      },
    });
    onModalCloseHandler();
  };

  const renderContent = () => {
    if (swappedFacesImageUrl) {
      return (
        <div className="text-center">
          <img
            src={swappedFacesImageUrl}
            alt=""
            className="rounded"
            style={{
              width: "90%",
              maxHeight: "calc(100vh - 300px)",
              objectFit: "contain",
            }}
          />

          <div className="d-flex gap-4 justify-content-center mt-4">
            <button
              className="btn btn-sm btn-secondary"
              onClick={onSwapFacesHandler}
            >
              {_intl.formatMessage({
                id: "common.tryAgin",
              })}
            </button>
            <button className="btn btn-sm btn-primary" onClick={onApplyHandler}>
              {_intl.formatMessage({
                id: "common.apply",
              })}
            </button>
          </div>
        </div>
      );
    }

    if (selectedImage) {
      return (
        <div className="text-center">
          <img
            src={selectedImage}
            alt=""
            className="rounded"
            style={{
              width: "90%",
              maxHeight: "calc(100vh - 300px)",
              objectFit: "contain",
            }}
          />

          <div className="d-flex gap-4 justify-content-center mt-4">
            <button
              className="btn btn-sm btn-secondary"
              onClick={onUploadClickHandler}
            >
              {_intl.formatMessage({
                id: "editor.image.swapFaces.selectAgain",
              })}
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={onSwapFacesHandler}
            >
              {_intl.formatMessage({
                id: "editor.image.swapFaces",
              })}
            </button>
          </div>
        </div>
      );
    }

    return (
      <div
        className="nav-link btn btn-outline btn-outline-dashed btn-color-gray-700 border-hover-primary btn-active btn-active-dark p-6 h-100px w-150px my-16"
        style={{ backgroundColor: getCSSVariableValue("--bs-gray-100") }}
        onClick={onUploadClickHandler}
      >
        <span className="">
          <button type="button" style={{ border: 0, background: "none" }}>
            <KTSVG className="svg-icon-1" path="/media/icons/plus.svg" />
            <div className="mt-4 text-gray-800">
              {_intl.formatMessage({ id: "editor.image.selectImage" })}
            </div>
          </button>
        </span>
      </div>
    );
  };

  return (
    <Modal
      id="kt_modal_image_detail"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered min-w-1000px"
      show={true}
      onHide={onModalCloseHandler}
    >
      <BaseLoading
        loading={isLoading}
        tip={
          isLoading ? _intl.formatMessage({ id: "common.aiLoadingTip" }) : null
        }
      >
        <div className="modal-header">
          <h2>{_intl.formatMessage({ id: "editor.image.uploadImage" })}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={onModalCloseHandler}
          >
            <KTSVG className="svg-icon-1" path="/media/icons/close.svg" />
          </div>
        </div>

        <div className="modal-body py-lg-10 px-lg-10 d-flex justify-content-center">
          <input
            ref={inputRef}
            accept="image/*"
            type="file"
            style={{ display: "none" }}
            onChange={onImageSelectHandler}
          />
          {renderContent()}
        </div>
      </BaseLoading>
    </Modal>
  );
};

export class SwapFacesTune {
  static get isTune() {
    return true;
  }

  constructor({ api }) {
    this.api = api;
    this.modalRoot = null;
    this.container = null;
  }

  getCurrentBlock = () => {
    const currentIndex = this.api.blocks.getCurrentBlockIndex();
    const currentBlock = this.api.blocks.getBlockByIndex(currentIndex);

    return [currentBlock, currentIndex];
  };

  isDisabled = () => {
    const [currentBlock] = this.getCurrentBlock();
    return currentBlock.isEmpty;
  };

  onActivateHandler = async () => {
    const [currentBlock] = this.getCurrentBlock();
    const imageBlock = currentBlock.holder.getElementsByClassName(
      "image-tool__image-picture"
    )?.[0];

    if (!imageBlock) {
      return;
    }

    if (!this.container) {
      this.container = document.createElement("div");
      document.body.appendChild(this.container);
    }

    this.modalRoot = createRoot(this.container);

    const onModalCloseHandler = () => {
      this.modalRoot.unmount();
    };

    this.modalRoot.render(
      <ImageUploadModal
        editorApi={this.api}
        currentBlock={currentBlock}
        imageBlock={imageBlock}
        onModalCloseHandler={onModalCloseHandler}
      />
    );
  };

  render() {
    return createTuneItem({
      label: _intl.formatMessage({ id: "editor.image.swapFaces" }),
      icon: SWAP_FACES_ICON,
      isDisable: this.isDisabled(),
      onActivate: this.onActivateHandler,
    });
  }
}
