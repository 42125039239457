/* eslint-disable react-hooks/exhaustive-deps */
import { FC, createContext, useContext, useEffect } from "react";
import useSetState from "../../hooks/useSetState";
import { statisticsApi } from "../../api";
import { WithChildren } from "../../../_metronic/helpers/react18MigrationHelpers";
import subscriptionApi from "../../api/subscriptionApi";

const INITIAL_STATE = {
  formattedData: {},
  loading: false,
  hasError: false,
  noData: false,
  currentPackage: {} as any,
  currentPackageLoading: false,
  currentPackageError: false,
};

const DashboardContext = createContext(INITIAL_STATE);

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};

const DashboardProvider: FC<WithChildren> = ({ children }) => {
  const [state, setState] = useSetState(INITIAL_STATE);

  useEffect(() => {
    fetchStatistics();
    fetchCurrentPackage();
  }, []);

  const fetchStatistics = async () => {
    try {
      setState({
        loading: true,
        hasError: false,
        noData: false,
      });

      const response = await statisticsApi.getStatistics();

      const trendsData = response.data?.trends;

      if (
        trendsData?.generated_content?.length === 0 &&
        trendsData?.paraphrase?.length &&
        trendsData?.paraphrase?.length
      ) {
        return setState({
          loading: false,
          noData: true,
        });
      }

      const generatedContent = trendsData.generated_content?.reduce(
        (acc: any, curr: any) => {
          const { date, word_count, sum_count, time_saved } = curr;
          acc.articleCount.push(sum_count);
          acc.timeSaved.push(time_saved);
          acc.wordCount.push(word_count);

          const dateFormat = date.split("-").reverse().join("-");
          acc.categories.push(dateFormat);

          return acc;
        },
        { articleCount: [], timeSaved: [], wordCount: [], categories: [] }
      );

      const paraphrase = trendsData.paraphrase?.reduce(
        (acc: any, curr: any) => {
          const { date, word_count, sum_count, time_saved } = curr;
          acc.articleCount.push(sum_count);
          acc.timeSaved.push(time_saved);
          acc.wordCount.push(word_count);

          const dateFormat = date.split("-").reverse().join("-");
          acc.categories.push(dateFormat);

          return acc;
        },
        { articleCount: [], timeSaved: [], wordCount: [], categories: [] }
      );

      const imageContent = trendsData.image_content?.reduce(
        (acc: any, curr: any) => {
          const { date, sum_count, time_saved } = curr;
          acc.imageCount.push(sum_count);
          acc.timeSaved.push(time_saved);

          const dateFormat = date.split("-").reverse().join("-");
          acc.categories.push(dateFormat);

          return acc;
        },
        { imageCount: [], timeSaved: [], categories: [] }
      );

      setState({
        formattedData: {
          generatedContent,
          paraphrase,
          imageContent,
        },
        loading: false,
        noData: false,
      });
    } catch (error) {
      setState({
        hasError: true,
        loading: false,
      });
    }
  };

  const fetchCurrentPackage = async () => {
    try {
      setState({
        currentPackageLoading: true,
        currentPackageError: false,
      });

      const response = await subscriptionApi.getSubscriptionUsage();
      setState({
        currentPackageLoading: false,
        currentPackage: response.data,
      });
    } catch (error) {
      setState({
        currentPackageLoading: false,
        currentPackageError: true,
      });
    }
  };

  const value = {
    ...state,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
