import httpClient from "./httpClient";

const generate = ({ topic, language }: any, signal: any) => {
  return httpClient.post(
    "/writer/generate",
    {
      topic,
      language,
    },
    { signal }
  );
};

const paraphrase = ({ text, language }: any, signal?: any) => {
  return httpClient.post(
    "/writer/paraphrase",
    {
      text,
      language,
    },
    { signal }
  );
};

const summarize = ({ text, language }: any, signal?: any) => {
  return httpClient.post(
    "/writer/summarize",
    {
      text,
      language,
    },
    { signal }
  );
};

const generateImage = ({ prompt, type }: any, signal: any) => {
  return httpClient.post(
    "/image/generate_image",
    { prompt },
    { params: { type }, signal }
  );
};

const upscaleImage = ({ image_url }: any, signal: any) => {
  return httpClient.post("/image/upscale_image", { image_url }, { signal });
};

const removeBackgroundImage = ({ image_url }: any, signal: any) => {
  return httpClient.post("/image/remove_background", { image_url }, { signal });
};

const swapFaces = ({ base_image, swap_image }: any, signal: any) => {
  return httpClient.post(
    "/image/swap_faces",
    { base_image, swap_image },
    { signal }
  );
};

const aiApi = {
  generate,
  paraphrase,
  summarize,
  generateImage,
  upscaleImage,
  removeBackgroundImage,
  swapFaces,
};

export default aiApi;
