import httpClient from "./httpClient";

const createPaymentLink = (package_id: number) => {
  return httpClient.get("/subscription/create_payment_link", {
    params: { package_id },
  });
};

const getSubscriptionUsage = () => {
  return httpClient.get("/subscription/usage");
};

const subscriptionApi = {
  createPaymentLink,
  getSubscriptionUsage,
};

export default subscriptionApi;
