import Paragraph from "@editorjs/paragraph";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Code from "@editorjs/code";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";

import documentApi from "../../api/documentApi";
import { Rephrase } from "./Tools/Rephrase";
import { CreateImageBlock } from "./Tools/CreateImageBlock";
import { CreateImageTune } from "./Tools/CreateImageTune";
import { CreateContentBlock } from "./Tools/CreateContentBlock";
import { CreateContentTune } from "./Tools/CreateContentTune";
import { SocketWriterBlock } from "./Tools/SocketWriterBlock";
import { Summarize } from "./Tools/Summarize";
import { MockWriter } from "./Tools/MockWriter";
import { CustomPromptTune } from "./Tools/CustomPromptTune";
import { UpscaleImageTune } from "./Tools/UpscaleImageTune";
import { RemoveBackgroundImageTune } from "./Tools/RemoveBackgroundImageTune";
import { SwapFacesTune } from "./Tools/SwapFacesTune";

export const EDITOR_TOOLS = {
  beSyncTool_createContentBlock: CreateContentBlock,
  beSyncTool_createImageBlock: CreateImageBlock,
  socketWriterBlock: SocketWriterBlock,
  mockWriter: MockWriter,
  paragraph: {
    class: Paragraph,
    tunes: ["customPromptTune", "rephrase", "summarize", "createImageTune"],
  },
  header: {
    class: Header,
    inlineToolbar: true,
    tunes: ["createContentTune", "customPromptTune", "rephrase"],
  },
  list: {
    class: List,
    inlineToolbar: true,
  },
  image: {
    class: Image,
    tunes: ["upscaleImageTune", "removeBackgroundImageTune", "swapFacesTune"],
    config: {
      uploader: {
        uploadByFile(file) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = (e) => {
              documentApi
                .uploadImage({
                  image: e.target.result,
                })
                .then((response) => {
                  resolve({
                    success: 1,
                    file: {
                      url: response.data.image_url,
                    },
                  });
                })
                .catch(() => {
                  resolve({
                    success: 0,
                    file: {
                      url: "",
                    },
                  });
                });
            };
          });
        },
      },
    },
  },
  checklist: {
    class: CheckList,
    inlineToolbar: true,
  },
  table: {
    class: Table,
    inlineToolbar: true,
  },
  code: {
    class: Code,
    inlineToolbar: true,
  },
  delimiter: Delimiter,
  inlineCode: InlineCode,
  marker: Marker,
  raw: {
    class: Raw,
    inlineToolbar: true,
  },
  quote: Quote,
  rephrase: Rephrase,
  summarize: Summarize,
  createImageTune: CreateImageTune,
  createContentTune: CreateContentTune,
  customPromptTune: CustomPromptTune,
  upscaleImageTune: UpscaleImageTune,
  removeBackgroundImageTune: RemoveBackgroundImageTune,
  swapFacesTune: SwapFacesTune,
};
