import { FC, useEffect, useRef } from "react";
import ApexCharts from "apexcharts";

const Chart: FC<any> = ({ chartOptions, className }) => {
	const chartRef = useRef<any>();
	const chartInstanceRef = useRef<any>();

	useEffect(() => {
		if (!chartRef.current) {
			return;
		}

		if (chartInstanceRef.current) {
			chartInstanceRef.current.updateOptions(chartOptions);
			return;
		}
		const chart = new ApexCharts(chartRef.current, chartOptions);

		if (chart) {
			setTimeout(() => {
				chart.render();
				chartInstanceRef.current = chart;
			}, 0);
		}
	}, [chartRef, chartOptions]);

	return <div ref={chartRef} className={className} />;
};

export default Chart;
