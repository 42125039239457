import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { getAuth, removeAuth } from "../pages/auth";

const httpClient: AxiosInstance = axios.create({
  baseURL: "https://api.besync.ai/app",
  headers: {
    "Content-Type": "application/json",
  },
});

// auth interceptor
httpClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const auth = getAuth();
    if (auth && auth.access_token && config.headers) {
      config.headers.Authorization = `Bearer ${auth.access_token}`;
    }

    return config;
  },
  (err: any) => Promise.reject(err)
);

// logout interceptor
httpClient.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (err: any) => {
    if (err.response && err.response.status === 401) {
      removeAuth();
      window.location.reload();
    }

    return Promise.reject(err);
  }
);
export default httpClient;
