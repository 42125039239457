/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { jobApi } from "../../../api";
import jobTypes from "../../../enums/jobTypes";
import MySwal from "../../../components/MySwal";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import BaseLoading from "../../../components/BaseLoading";
import { PageActions, PageTitle } from "../../../../_metronic/layout/core";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import ImageItem from "./ImageItem";
import ImageDetailModal from "./ImageDetailModal";

const DEFAULT_IMAGE_CARD_WIDTH: number = 250;

export const ImageList: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const gridRef = useRef<any>();
  const allImages = useRef([]);
  const numberOfItemsPerRowRef = useRef<number>(4);
  const displayImagesLengthRef = useRef<number>(0);

  const [gridWidth, setGridWidth] = useState<number>(0);
  const [cardWidth, setCardWidth] = useState<number>(0);

  const [images, setImages] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [imageSelectionIds, setImageSelectionIds] = useState<any>([]);

  useEffect(() => {
    fetchImages();
  }, []);

  useEffect(() => {
    const setGridHandler = () => {
      if (gridRef != null && gridRef.current != null) {
        setTimeout(() => {
          setGridWidth(gridRef.current?.offsetWidth || 0);
        }, 300);
      }
    };

    const scrollHandler = (e: any) => {
      if (displayImagesLengthRef.current === allImages.current.length) {
        return;
      }

      const target = e.target.scrollingElement;
      const scrollHeight = target.scrollHeight;
      const scrollTop = target.scrollTop;
      const clientHeight = target.clientHeight;

      let diffHeight = scrollHeight - scrollTop;
      diffHeight = diffHeight * 0.7;

      if (clientHeight > diffHeight) {
        const displayImages = allImages.current.slice(
          0,
          displayImagesLengthRef.current + numberOfItemsPerRowRef.current * 5
        );
        displayImagesLengthRef.current = displayImages.length;
        setImages(displayImages);
      }
    };

    window.addEventListener("resize", setGridHandler);
    window.addEventListener("scroll", scrollHandler);
    setGridWidth(gridRef.current.offsetWidth);
    return () => {
      document.removeEventListener("resize", setGridHandler);
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  useEffect(() => {
    if (gridWidth > 0) {
      const numberOfItemsPerRow = Math.floor(
        Number(gridWidth / DEFAULT_IMAGE_CARD_WIDTH)
      );
      numberOfItemsPerRowRef.current = numberOfItemsPerRow;
      const _cardWidth =
        gridWidth / (numberOfItemsPerRow === 0 ? 1 : numberOfItemsPerRow);
      setCardWidth(_cardWidth - 20);
    }
  }, [gridWidth]);

  const fetchImages = async () => {
    try {
      setLoading(true);
      const response = await jobApi.getJobList({
        type: jobTypes.IMAGE_CONTENT,
      });

      allImages.current = response.data;
      const displayImages = response.data.slice(
        0,
        numberOfItemsPerRowRef.current * 5
      );
      displayImagesLengthRef.current = displayImages.length;

      setImages(displayImages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError();
    }
  };

  const showError = () => {
    MySwal.fire({
      icon: "error",
      title: "Oops...",
      text: intl.formatMessage({ id: "common.generalError" }),
      confirmButtonText: intl.formatMessage({ id: "common.close" }),
      confirmButtonColor: getCSSVariableValue("--bs-primary"),
    });
  };

  const onDeleteHandler = (id: number, fromDetailModal: boolean = false) => {
    MySwal.fire({
      title: intl.formatMessage({ id: "common.delete.confirm" }),
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: intl.formatMessage({ id: "common.cancel" }),
      confirmButtonText: intl.formatMessage({ id: "common.delete" }),
      confirmButtonColor: getCSSVariableValue("--bs-danger"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (fromDetailModal) {
            onDetailModalVisibilityHandler(false);
          }
          MySwal.showLoading();
          await jobApi.deleteJob({ job_id: id });
          setImages((prevData: any) => {
            return prevData.filter((p: any) => {
              return p.id !== id;
            });
          });

          MySwal.fire({
            icon: "success",
            title: intl.formatMessage({ id: "common.delete.success" }),
            confirmButtonText: intl.formatMessage({ id: "common.close" }),
            confirmButtonColor: getCSSVariableValue("--bs-primary"),
          });
        } catch (error) {
          showError();
        }
      }
    });
  };

  const deleteSelectedHandler = () => {
    MySwal.fire({
      title: intl.formatMessage({ id: "common.delete.confirm" }),
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: intl.formatMessage({ id: "common.cancel" }),
      confirmButtonText: intl.formatMessage({ id: "common.delete" }),
      confirmButtonColor: getCSSVariableValue("--bs-danger"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          MySwal.showLoading();
          const allTasks = imageSelectionIds.map((id: any) => {
            return jobApi.deleteJob({ job_id: id });
          });
          await Promise.all(allTasks);

          setImages((prevData: any) => {
            const newData = prevData.filter((p: any) => {
              return !imageSelectionIds.includes(p.id);
            });
            return newData;
          });

          MySwal.fire({
            icon: "success",
            title: intl.formatMessage({ id: "common.delete.success" }),
            confirmButtonText: intl.formatMessage({ id: "common.close" }),
            confirmButtonColor: getCSSVariableValue("--bs-primary"),
          });
        } catch (error) {
          showError();
          fetchImages();
        } finally {
          setImageSelectionIds([]);
        }
      }
    });
  };

  const onDetailModalVisibilityHandler = (
    value: boolean,
    image: any = null
  ) => {
    if (value) {
      setIsDetailModalVisible(true);
      setSelectedImage(image);
    } else {
      setIsDetailModalVisible(false);
      setSelectedImage({});
    }
  };

  return (
    <div className="w-100 position-relative min-h-400px">
      <PageTitle>
        <FormattedMessage id="image.generatedImages" />
      </PageTitle>
      <PageActions>
        {imageSelectionIds.length > 0 && (
          <div className="d-flex justify-content-end align-items-center me-4">
            <div className="fw-bold me-5">
              <span className="me-2">{imageSelectionIds.length}</span>{" "}
              <FormattedMessage id="common.selected" />
            </div>

            <button
              type="button"
              className="btn btn-danger"
              onClick={deleteSelectedHandler}
            >
              <KTSVG path="/media/icons/delete.svg" className="svg-icon-3" />
              <FormattedMessage id="common.deleteSelected" />
            </button>
          </div>
        )}
        <a
          className="btn btn-primary"
          onClick={() => {
            navigate("/ai-powered/image/create");
          }}
        >
          <KTSVG
            path="/media/icons/brush.svg"
            className="svg-icon-2 svg-icon-gray-500 me-4"
          />
          <FormattedMessage id="image.generateImage" />
        </a>
      </PageActions>
      <BaseLoading loading={loading}>
        <div
          className="w-100 d-flex flex-wrap mb-10"
          style={{ gap: 20 }}
          ref={gridRef}
        >
          {!loading && images?.length === 0 ? (
            <div className="card card-body">
              <div className="w-150px text-center m-auto mt-20 mb-20">
                <SVG src={toAbsoluteUrl("/media/icons/empty.svg")} />
                <span>
                  <FormattedMessage id="common.noData" />
                </span>
              </div>
            </div>
          ) : (
            images.map((image: any) => {
              return (
                <ImageItem
                  key={image.id}
                  image={image}
                  cardWidth={cardWidth}
                  setImageSelectionIds={setImageSelectionIds}
                  onDeleteHandler={onDeleteHandler}
                  onDetailModalVisibilityHandler={
                    onDetailModalVisibilityHandler
                  }
                />
              );
            })
          )}
        </div>
        <div className="d-flex w-100 flex-wrap gap-10"></div>
      </BaseLoading>

      {isDetailModalVisible && (
        <ImageDetailModal
          isDetailModalVisible={isDetailModalVisible}
          selectedImage={selectedImage}
          onDetailModalVisibilityHandler={onDetailModalVisibilityHandler}
          onDeleteHandler={onDeleteHandler}
        />
      )}
    </div>
  );
};
