/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";

import { _intl } from "../../../../_metronic/layout/MasterInit";
import { ERROR_EVENT } from "../events";
import { getTempEditor } from "../utils";
import { MOCK_CONTENT } from "../../TrendMagic";

const View = ({ editorApi }) => {
  const displayContentRef = useRef();

  const [createdContent, setCreatedContent] = useState("");
  const [isFinish, setIsFinish] = useState(false);
  const [dots, setDots] = useState(".");

  useEffect(() => {
    createContent();
  }, []);

  const createContent = async () => {
    try {
      const dotsInterval = setInterval(() => {
        setDots((prevDots) => (prevDots.length >= 3 ? "." : prevDots + "."));
      }, 300);

      let i = 4;

      const nextLetter = () => {
        setCreatedContent(MOCK_CONTENT.slice(0, i));
        if (i < MOCK_CONTENT.length) {
          i += 4;
          setTimeout(() => {
            nextLetter();
          }, 12);
        } else {
          setIsFinish(true);
          const createEvent = new CustomEvent("ended-create-content");
          window.dispatchEvent(createEvent);
        }
      };

      setTimeout(() => {
        clearInterval(dotsInterval);
        nextLetter();
      }, 1000);
    } catch (error) {
      editorApi.events.emit(ERROR_EVENT);
    }
  };

  const onOkHandler = async () => {
    const tempEditor = await getTempEditor();
    await tempEditor.blocks.renderFromHTML(createdContent);

    setTimeout(() => {
      tempEditor.save().then((data) => {
        editorApi.blocks.delete(editorApi.blocks.getCurrentBlockIndex());
        editorApi.blocks.insertMany(
          data.blocks,
          editorApi.blocks.getCurrentBlockIndex() + 1
        );
      });
    }, 150);
  };

  return (
    <div className="w-full border rounded p-4 mb-2 align-items-center">
      {createdContent === "" && <div className="fs-1">{dots}</div>}
      <div
        ref={displayContentRef}
        dangerouslySetInnerHTML={{ __html: createdContent }}
      ></div>
      {isFinish && (
        <div className="d-flex gap-4 mt-10 align-items-center">
          <button className="btn btn-sm btn-primary" onClick={onOkHandler}>
            {_intl.formatMessage({ id: "editor.convertToBlocks" })}
          </button>
        </div>
      )}
    </div>
  );
};

export class MockWriter {
  viewRoot;
  static get toolbox() {
    return null;
  }

  constructor(params) {
    this.api = params.api;
    this.data = params.data;
  }

  render() {
    const container = document.createElement("div");
    this.viewRoot = createRoot(container);
    this.viewRoot.render(<View editorApi={this.api} />);

    return container;
  }

  save(blockContent) {
    return {
      url: blockContent.value,
    };
  }

  destroy() {
    setTimeout(() => {
      this.viewRoot.unmount();
    });
  }
}
