import { _intl } from "../../../../_metronic/layout/MasterInit";
import { aiApi } from "../../../api";
import { swalUtil } from "../../../utils/swalUtil";
import { LOADING_END_EVENT, LOADING_START_EVENT } from "../events";
import { CREATE_IMAGE_ICON } from "../Icons";
import { createTuneItem } from "../utils";

export class CreateImageTune {
  static get isTune() {
    return true;
  }

  constructor({ api }) {
    this.api = api;
  }

  getCurrentBlock = () => {
    const currentIndex = this.api.blocks.getCurrentBlockIndex();
    const currentBlock = this.api.blocks.getBlockByIndex(currentIndex);

    return [currentBlock, currentIndex];
  };

  isDisabled = () => {
    const [currentBlock] = this.getCurrentBlock();
    return currentBlock.isEmpty;
  };

  onActivateHandler = async () => {
    try {
      this.api.events.emit(LOADING_START_EVENT);
      const [currentBlock] = this.getCurrentBlock();
      const content = currentBlock.holder.innerText;

      const response = await aiApi.generateImage({
        prompt: content,
        type: "paragraph",
      });

      this.api.blocks.insert("image", {
        file: {
          url: response.data.image_url,
        },
      });
      this.api.events.emit(LOADING_END_EVENT);
    } catch (error) {
      this.api.events.emit(LOADING_END_EVENT);
      swalUtil.showErrorMessage(error);
    }
  };

  render() {
    return createTuneItem({
      label: _intl.formatMessage({ id: "editor.image.create" }),
      icon: CREATE_IMAGE_ICON,
      isDisable: this.isDisabled(),
      onActivate: this.onActivateHandler,
    });
  }
}
