import httpClient from "./httpClient";

const getAllDocuments = () => {
  return httpClient.get("/document/get_all_documents");
};

const getDocument = ({ document_id }: any) => {
  return httpClient.get("/document/get_document", { params: { document_id } });
};

const insertDocument = ({ title, document_body }: any) => {
  return httpClient.post("/document/insert_document", { title, document_body });
};

const updateDocument = ({ title, document_body, document_id }: any) => {
  return httpClient.put(
    "/document/update_document",
    { title, document_body },
    { params: { document_id } }
  );
};

const deleteDocument = ({ document_id }: any) => {
  return httpClient.delete("/document/delete_document", {
    params: { document_id },
  });
};

const uploadImage = ({ image }: any) => {
  return httpClient.post("/document/upload_image", { image });
};

const documentApi = {
  getAllDocuments,
  getDocument,
  insertDocument,
  updateDocument,
  deleteDocument,
  uploadImage,
};

export default documentApi;
