import { FC, useState } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import userApi from "../../../../api/userApi";
import { useAuth } from "../../core/Auth";
import MySwal from "../../../../components/MySwal";
import { getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils";

const initialValues = {
  verificationCode: "",
};

const EmailVerify: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { tempEmail, tempPackage, saveAuth, setCurrentUser } = useAuth();

  const [loading, setLoading] = useState(false);

  const emailVerifySchema = Yup.object().shape({
    verificationCode: Yup.string().required(
      intl.formatMessage({ id: "auth.verifyEmail.verificationCode.required" })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: emailVerifySchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const response = await userApi.verifyEmail(
          tempEmail,
          values.verificationCode
        );

        const auth = { access_token: response.data.access_token };
        saveAuth(auth);
        const { data: user } = await userApi.getCurrentUser();
        setCurrentUser(user);
        if (tempPackage?.id) {
          navigate("/subscription");
        }

        MySwal.fire({
          icon: "success",
          title: intl.formatMessage({ id: "auth.verifyEmail.success" }),
          text: intl.formatMessage({
            id: tempPackage?.id
              ? "auth.verifyEmail.success.desc.subscription"
              : "auth.verifyEmail.success.desc.free",
          }),
          confirmButtonText: intl.formatMessage({
            id: tempPackage?.id
              ? "common.continue"
              : "auth.verifyEmail.success.button.free",
          }),
          confirmButtonColor: getCSSVariableValue("--bs-primary"),
        });
      } catch (error) {
        setStatus(intl.formatMessage({ id: "auth.verifyEmail.error" }));
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const onClickResendHandler = async () => {
    try {
      await userApi.resendVerificationCode(tempEmail);
      MySwal.fire({
        icon: "success",
        title: intl.formatMessage({ id: "auth.verifyEmail.resend.success" }),
        confirmButtonText: intl.formatMessage({ id: "common.close" }),
        confirmButtonColor: getCSSVariableValue("--bs-primary"),
      });
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops..",
        text: intl.formatMessage({ id: "common.generalError" }),
        confirmButtonText: intl.formatMessage({ id: "common.close" }),
        confirmButtonColor: getCSSVariableValue("--bs-primary"),
      });
    }
  };

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_verify_email"
      onSubmit={formik.handleSubmit}
    >
      <div className="mb-10 text-center">
        {/* begin::Title */}
        <h1 className="text-dark mb-3">
          <FormattedMessage id="auth.verifyEmail.title" />
        </h1>

        <div className="text-gray-400 fw-semibold fs-4">
          <FormattedMessage id="auth.verifyEmail.description" />
        </div>
        {/* end::Title */}
      </div>

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      <div className="fv-row mb-7">
        <label className="form-label fw-bold text-dark fs-6">
          <FormattedMessage id="auth.verifyEmail.verificationCode" />
        </label>
        <input
          placeholder={intl.formatMessage({
            id: "auth.verifyEmail.verificationCode",
          })}
          autoComplete="off"
          {...formik.getFieldProps("verificationCode")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid":
                formik.touched.verificationCode &&
                formik.errors.verificationCode,
            },
            {
              "is-valid":
                formik.touched.verificationCode &&
                !formik.errors.verificationCode,
            }
          )}
        />
        {formik.touched.verificationCode && formik.errors.verificationCode && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.verificationCode}</span>
            </div>
          </div>
        )}
      </div>

      <div className="text-center">
        <button
          type="submit"
          id="kt_email_verify_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label">
              {" "}
              <FormattedMessage id="common.submit" />{" "}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              <FormattedMessage id="common.pleaseWait" />{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>

        <div className="text-center text-gray-400 fw-semibold fs-4 mt-2">
          <FormattedMessage id="auth.verifyEmail.didNotGetCode" />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="btn btn-active-color-gray-800 p-0 mx-3"
            onClick={onClickResendHandler}
          >
            <FormattedMessage id="auth.verifyEmail.resend" />
          </a>
        </div>
      </div>
    </form>
  );
};

export default EmailVerify;
