/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { DateTime } from "luxon";
import { KTSVG } from "../../../../_metronic/helpers";
import BaseLoading from "../../../components/BaseLoading";
import { useDashboardContext } from "../DashboardProvider";

const PackageDetailCard: FC = () => {
  const { currentPackage, currentPackageLoading } = useDashboardContext();

  const dateIcon = (
    <KTSVG className="svg-icon-1 me-2" path="/media/icons/calendar.svg" />
  );

  return (
    <div className="card h-100">
      <div className="card-body">
        <BaseLoading loading={currentPackageLoading}>
          <div className="fs-2 fw-semibold text-gray-900 mb-10">
            <FormattedMessage id="dashboard.packageDetail" />
          </div>

          <div className="d-flex align-items-center gap-16">
            <div className="d-flex flex-column gap-6">
              <div>
                <div className="mb-2 text-gray-600">
                  <FormattedMessage id="dashboard.packageName" />
                </div>
                <div className="d-flex fs-3 text-gray-900 align-items-center">
                  {currentPackage.package_name}
                </div>
              </div>
              <div>
                <div className="mb-2 text-gray-600">
                  <FormattedMessage id="dashboard.packagePeriod" />
                </div>
                <div className="d-flex fs-3 text-gray-900 align-items-center">
                  <FormattedMessage
                    id={`dashboard.period.${
                      currentPackage.period || "monthly"
                    }`}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-column gap-6">
              <div>
                <div className="mb-2 text-gray-600">
                  <FormattedMessage id="dashboard.subscriptionStart" />
                </div>
                <div className="d-flex fs-3 text-gray-900 align-items-center">
                  {dateIcon}
                  {DateTime.fromISO(currentPackage.start_date).toFormat(
                    "dd/MM/yyyy"
                  )}
                </div>
              </div>
              <div>
                <div className="mb-2 text-gray-600">
                  <FormattedMessage id="dashboard.subscriptionEnd" />
                </div>
                <div className="d-flex fs-3 text-gray-900 align-items-center">
                  {dateIcon}
                  {DateTime.fromISO(currentPackage.end_date).toFormat(
                    "dd/MM/yyyy"
                  )}
                </div>
              </div>
            </div>
          </div>
        </BaseLoading>
      </div>
    </div>
  );
};

export default PackageDetailCard;
