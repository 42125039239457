import { FC, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import userApi from "../../../api/userApi";

const initialValues = {
  email: "",
};

const ForgotPassword: FC = () => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "auth.email.wrongFormat" }))
      .max(50, intl.formatMessage({ id: "auth.email.max" }))
      .required(intl.formatMessage({ id: "auth.email.required" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);

      try {
        await userApi.forgotPassword(values.email);
        setHasErrors(false);
        setLoading(false);
      } catch (error) {
        setHasErrors(true);
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">
            <FormattedMessage id="auth.forgotPassword" />
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-semibold fs-4">
            <FormattedMessage id="auth.forgotPassword.description" />
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              <FormattedMessage id="auth.forgotPassword.error" />
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="mb-10 bg-light-info p-8 rounded">
            <div className="text-info">
              <FormattedMessage id="auth.forgotPassword.success" />
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="form-label fw-bold text-gray-900 fs-6">
            <FormattedMessage id="auth.email" />
          </label>
          <input
            type="email"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bold me-4"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <FormattedMessage id="common.cancel" />
            </button>
          </Link>
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bold"
          >
            {!loading ? (
              <span className="indicator-label">
                <FormattedMessage id="common.submit" />
              </span>
            ) : (
              <span className="indicator-loading">
                <FormattedMessage id="common.pleaseWait" />
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
};

export default ForgotPassword;
