export const LANGUAGES = [
  { code: "tr",
    name: "Turkish",
    nativeName: "Türkçe",
  },
  {
    code: "de",
    name: "German",
    nativeName: "Deutsch",
  },
  {
    code: "en",
    name: "English",
    nativeName: "English",
  },
  {
    code: "es",
    name: "Spanish",
    nativeName: "Español",
  },
  {
    code: "fr",
    name: "French",
    nativeName: "Français",
  },
  {
    code: "it",
    name: "Italian",
    nativeName: "Italiano",
  },
  {
    code: "pt",
    name: "Portuguese",
    nativeName: "Português",
  },
  {
    code: "ru",
    name: "Russian",
    nativeName: "Русский",
  },
  {
    code: "pl",
    name: "Polish",
    nativeName: "Polski",
  },
  {
    code: "da",
    name: "Danish",
    nativeName: "Dansk",
  },
  {
    code: "nl",
    name: "Dutch",
    nativeName: "Nederlands",
  },
  {
    code: "nb",
    name: "Norwegian",
    nativeName: "Norsk",
  },
  {
    code: "fi",
    name: "Finnish",
    nativeName: "Suomi",
  },
  {
    code: "sv",
    name: "Swedish",
    nativeName: "Svenska",
  },
  {
    code: "he",
    name: "Hebrew",
    nativeName: "עברית",
  },
  {
    code: "ar",
    name: "Arabic",
    nativeName: "العربية",
  },
  {
    code: "hi",
    name: "Hindi",
    nativeName: "हिन्दी",
  },
  {
    code: "ko",
    name: "Korean",
    nativeName: "한국어",
  },
  {
    code: "zh",
    name: "Chinese",
    nativeName: "中文",
  },
  {
    code: "ja",
    name: "Japanese",
    nativeName: "日本語",
  },
];
