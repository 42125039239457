import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import Login from "./components/Login";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = "none";
    return () => {};
  }, []);

  return (
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo */}
        <span className="mb-12">
          <img
            alt="Logo"
            src={toAbsoluteUrl(
              window.devicePixelRatio > 1
                ? "/media/logos/besync-logo-2x.svg"
                : "/media/logos/besync-logo.png"
            )}
            className="w-250px"
          />
        </span>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage, AuthLayout };
