import { FC, useEffect, useMemo, useState } from "react";
import _get from "lodash/get";
import BaseLoading from "../BaseLoading";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const range = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const DOTS = "•••";

const DEFAULT_PER_PAGE = 10;

const BaseTable: FC<any> = ({
  loading,
  columns,
  data,
  pagination,
  rowKey,
  rowSelection,
}) => {
  const [displayData, setDisplayData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const perPage = pagination?.perPage || DEFAULT_PER_PAGE;
    const start = (currentPage - 1) * perPage;
    const end = currentPage * perPage;
    const _displayData = data.slice(start, end) || [];
    setDisplayData(_displayData);
  }, [data, currentPage, pagination?.perPage]);

  const tableHead = useMemo(() => {
    return (
      <thead>
        <tr className="fw-bold text-muted">
          <th className="w-25px">
            <div className="form-check form-check-sm form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                value="1"
                data-kt-check="true"
                checked={
                  displayData.length !== 0 &&
                  rowSelection.selectedRowKeys.length === displayData.length
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    const newSelectedRowKeys = displayData.map((d, index) => {
                      return d?.[rowKey] || index;
                    });
                    rowSelection.onChange(newSelectedRowKeys);
                  } else {
                    rowSelection.onChange([]);
                  }
                }}
              />
            </div>
          </th>

          {columns.map((col: any) => {
            const { title, className, key } = col;
            return (
              <th key={key} className={className}>
                {title}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }, [columns, rowSelection, displayData, rowKey]);

  const tableBody = useMemo(() => {
    return displayData.map((item, index) => {
      const _rowKey = item[rowKey] || index;
      const row = columns.map((col: any) => {
        const { dataIndex, render, key, className } = col;

        const cellValue = _get(item, dataIndex, "");
        const cellContent =
          typeof render === "function" ? render(cellValue, item) : cellValue;
        return (
          <td key={`${_rowKey}-${key}`} className={className}>
            {cellContent}
          </td>
        );
      });

      row.unshift(
        <td key={`${item[rowKey]}-check`}>
          <div className="form-check form-check-sm form-check-custom form-check-solid">
            <input
              className="form-check-input widget-9-check"
              type="checkbox"
              value="1"
              checked={rowSelection?.selectedRowKeys?.includes(_rowKey)}
              onChange={(e) => {
                const newSelectedRowKeys = [
                  ...(rowSelection?.selectedRowKeys || []),
                ];
                if (e.target.checked) {
                  newSelectedRowKeys.push(_rowKey);
                } else {
                  const removedIndex = newSelectedRowKeys.indexOf(_rowKey);
                  newSelectedRowKeys.splice(removedIndex, 1);
                }

                rowSelection.onChange(newSelectedRowKeys);
              }}
            />
          </div>
        </td>
      );

      return <tr key={_rowKey}>{row}</tr>;
    });
  }, [displayData, columns, rowKey, rowSelection]);

  const paginationRange = useMemo(() => {
    const siblingCount = 1;
    const { perPage, totalCount } = pagination || {};
    const totalPageCount = Math.ceil(
      (totalCount || data.length) / (perPage || DEFAULT_PER_PAGE)
    );

    const totalPageNumbers = siblingCount + 5;
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [pagination, data, currentPage]);

  const withPrevDisabled = currentPage === 1 ? "disabled" : "";
  const withNextDisabled =
    currentPage === [...(paginationRange || [])].reverse()[0] ? "disabled" : "";

  return (
    <BaseLoading
      loading={loading}
      tip={<FormattedMessage id="common.pleaseWait" />}
    >
      <div className="table-responsive">
        {/* MARK: - Table */}
        <table
          style={loading ? { minHeight: 400 } : {}}
          className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
        >
          {/* MARK: - Table head */}
          {tableHead}

          {/* MARK: - Table body */}
          <tbody>{tableBody}</tbody>
        </table>
        {data.length === 0 && !loading && (
          <div className="w-150px text-center m-auto mt-20 mb-20">
            <SVG src={toAbsoluteUrl("/media/icons/empty.svg")} />
            <span>
              <FormattedMessage id="common.noData" />
            </span>
          </div>
        )}

        {/* MARK: - Pagination */}
        {data?.length > 0 && (
          <ul className="pagination align-items-center">
            <li className={`page-item previous ${withPrevDisabled}`}>
              {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="page-link"
                onClick={() => setCurrentPage((prev) => --prev)}
              >
                <i className="previous" />
              </a>
            </li>

            {paginationRange?.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return (
                  <li key={index} style={{ letterSpacing: 2 }}>
                    {DOTS}
                  </li>
                );
              }

              const withActive = pageNumber === currentPage ? "active" : "";

              return (
                <li key={index} className={`page-item ${withActive}`}>
                  {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() => setCurrentPage(Number(pageNumber))}
                    className="page-link cursor-pointer"
                  >
                    {pageNumber}
                  </a>
                </li>
              );
            })}

            <li className={`page-item next ${withNextDisabled}`}>
              {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="page-link"
                onClick={() => setCurrentPage((prev) => ++prev)}
              >
                <i className="next" />
              </a>
            </li>
          </ul>
        )}
      </div>
    </BaseLoading>
  );
};

export default BaseTable;
