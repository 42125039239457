import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { LANGUAGES } from "../constants/languages";
import { useThemeMode } from "../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import { BaseSelect } from "./BaseSelect";

const LANGUAGE_OPTIONS = LANGUAGES.map((l) => {
  return {
    value: l.name,
    label: l.nativeName,
  };
});

const SelectLanguage: FC<any> = ({
  initialValue,
  onChange,
  placeholder,
  menuPlacement,
}) => {
  useThemeMode();

  const value = useMemo(() => {
    return LANGUAGE_OPTIONS.find((l) => {
      return l.value === initialValue;
    });
  }, [initialValue]);

  return (
    <>
      <label className="form-label">
        <FormattedMessage id="common.language" />:
      </label>

      <BaseSelect
        className="w-150px"
        options={LANGUAGE_OPTIONS}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        menuPlacement={menuPlacement}
      />
    </>
  );
};

export default SelectLanguage;
