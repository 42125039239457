import { io } from "socket.io-client";

export const getSocketConnection = () => {
  const socketConnection = io("https://api.besync.io", {
    transports: ["websocket", "polling"],
    path: "/ws/generator/socket",
  });

  return socketConnection;
};
