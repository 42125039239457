/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { EDITOR_TOOLS } from "./constants";
import { ERROR_EVENT, LOADING_END_EVENT, LOADING_START_EVENT } from "./events";
import BaseLoading from "../BaseLoading";
import { KTSVG } from "../../../_metronic/helpers";
import { EditorSettings } from "./EditorSettings";
import MySwal from "../MySwal";
import { getCSSVariableValue } from "../../../_metronic/assets/ts/_utils";
import { countLetters, countWords } from "../../utils";
import documentApi from "../../api/documentApi";

export const Editor: FC<any> = ({ initialData, loading, documentId }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const editorRef = useRef<any>();
  const isSavingRef = useRef<any>(false);
  const documentIdRef = useRef<any>(documentId);
  const contentRef = useRef<any>();
  const countDataRef = useRef<any>();

  const [isLoading, setIsLoading] = useState(false);
  const [isSavingLoading, setIsSavingLoading] = useState(false);
  const [isVisibleEditorSettings, setIsVisibleEditorSettings] = useState(false);
  const [countData, setCountData] = useState({
    letterCount: 0,
    wordCount: 0,
  });

  useEffect(() => {
    if (editorRef.current == null) {
      initEditor();
    }

    const handleKeyPress = (event: any) => {
      // cmd+s handler
      if ((event.metaKey || event.ctrlKey) && event.key === "s") {
        event.preventDefault();
        if (!isSavingRef.current) {
          onSaveHandler({ silence: true });
        }
      }

      if ((event.metaKey || event.ctrlKey) && event.key === "z") {
        if (countDataRef.current.wordCount === 0) {
          event.preventDefault();
          editorRef.current.render(contentRef.current);
        }
      }
    };

    const createContent = () => {
      editorRef.current.blocks.insert("mockWriter", null, undefined, 1);
    };

    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("create-content", createContent);

    return () => {
      editorRef.current?.destroy();
      editorRef.current = null;
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("create-content", createContent);
    };
  }, []);

  useEffect(() => {
    if (initialData) {
      editorRef.current.render(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    countDataRef.current = countData;
  }, [countData]);

  const initEditor = () => {
    const editor = new EditorJS({
      holder: "editorjs",
      onReady: () => {
        editorRef.current = editor;
        editorRef.current.configuration.defaultBlock = "paragraph";

        editor.on(LOADING_START_EVENT, () => {
          setIsLoading(true);
        });

        editor.on(LOADING_END_EVENT, () => {
          setIsLoading(false);
        });

        editor.on(ERROR_EVENT, () => {
          MySwal.fire({
            icon: "error",
            title: "Oops...",
            text: intl.formatMessage({ id: "common.generalError" }),
            confirmButtonText: intl.formatMessage({ id: "common.close" }),
            confirmButtonColor: getCSSVariableValue("--bs-primary"),
          });
        });
      },
      placeholder: intl.formatMessage({ id: "common.untitled" }),
      data: initialData,
      onChange: async () => {
        editorRef.current?.toolbar?.open();
        if (typeof editor.save !== "function") {
          return;
        }
        const content: any = await editor.save();

        const { letterCount, wordCount } = content?.blocks
          ?.filter(function couldBeCounted(block: any) {
            return "text" in block.data;
          })
          ?.reduce(
            (sum: any, block: any) => {
              sum.wordCount += countWords(block.data.text);
              sum.letterCount += countLetters(block.data.text);

              return sum;
            },
            { wordCount: 0, letterCount: 0 }
          );
        setCountData({ letterCount, wordCount });
        if (wordCount > 0) {
          contentRef.current = content;
        }
      },
      defaultBlock: "header",
      tools: EDITOR_TOOLS,
      inlineToolbar: true,
    });
  };

  const onChangeEditorSettingsVisibility = useCallback(() => {
    setIsVisibleEditorSettings((prev) => {
      return !prev;
    });
  }, []);

  const onSaveHandler = async ({ silence = false }: any) => {
    isSavingRef.current = true;
    const document = await editorRef.current.save();
    const firstBlock = document.blocks[0];

    if (firstBlock?.type !== "header") {
      MySwal.fire({
        icon: "warning",
        title: intl.formatMessage({ id: "editor.title.warning" }),
        confirmButtonText: intl.formatMessage({ id: "common.close" }),
        confirmButtonColor: getCSSVariableValue("--bs-primary"),
      });
      return;
    }

    const title = firstBlock.data.text?.replace(/&nbsp;/g, " ");
    try {
      setIsSavingLoading(true);

      if (documentIdRef.current) {
        await documentApi.updateDocument({
          title,
          document_body: document,
          document_id: documentIdRef.current,
        });
      } else {
        const resp = await documentApi.insertDocument({
          title,
          document_body: document,
        });
        documentIdRef.current = resp.data.id;
      }

      !silence &&
        MySwal.fire({
          icon: "success",
          title: intl.formatMessage({ id: "common.save.success" }),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: intl.formatMessage({ id: "common.close" }),
          confirmButtonText: intl.formatMessage({ id: "common.continue" }),
          confirmButtonColor: getCSSVariableValue("--bs-primary"),
        }).then((result) => {
          if (result.isDismissed) {
            navigate(-1);
          }
        });
    } catch (error) {
      !silence &&
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: intl.formatMessage({ id: "common.generalError" }),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: intl.formatMessage({ id: "common.close" }),
          confirmButtonText: intl.formatMessage({ id: "common.tryAgin" }),
          confirmButtonColor: getCSSVariableValue("--bs-primary"),
        }).then((result) => {
          if (result.isConfirmed) {
            onSaveHandler({ title, document });
          }
        });
    } finally {
      setIsSavingLoading(false);
      isSavingRef.current = false;
    }
  };

  const separator = <span className="w-1px h-20px bg-gray-300" />;

  return (
    <BaseLoading
      loading={isLoading || loading || isSavingLoading}
      tip={isLoading ? <FormattedMessage id="common.aiLoadingTip" /> : null}
    >
      <div style={{ height: "calc(100vh - 200px)", overflow: "auto" }}>
        <div id="editorjs" />

        <div
          className="position-absolute d-flex justify-content-center"
          style={{
            left: "50%",
            bottom: 16,
            zIndex: 999,
            minWidth: 460,
            width: "max-content",
          }}
        >
          <div
            style={{ left: "-50%" }}
            className="d-flex gap-4 position-relative bg-gray-100 py-2 px-4 border rounded border-gray-200 align-items-center align-self-center"
          >
            {/* MARK: - Word count */}
            <span className="text-gray-700">
              <FormattedMessage
                id="editor.wordCount"
                values={{ wordCount: countData.wordCount }}
              />
            </span>

            {separator}

            {/* MARK: - Letter count */}
            <span className="text-gray-700">
              <FormattedMessage
                id="editor.letterCount"
                values={{ letterCount: countData.letterCount }}
              />
            </span>
            {separator}

            {/* MARK: - Action buttons */}
            <button
              className="btn btn-sm d-flex align-items-center"
              onClick={onChangeEditorSettingsVisibility}
            >
              <KTSVG path="/media/icons/cog.svg" className="svg-icon-7" />
              <FormattedMessage id="common.settings" />
            </button>
            {isVisibleEditorSettings && (
              <EditorSettings
                closeEditorSettings={onChangeEditorSettingsVisibility}
              />
            )}
            <button
              onClick={() => {
                navigate(-1);
              }}
              type="button"
              className="btn btn-sm btn-secondary"
            >
              <span style={{ marginLeft: -4 }}>
                <KTSVG
                  path="/media/icons/close.svg"
                  className="svg-icon-4 mt-n1"
                />
              </span>
              <FormattedMessage id="common.close" />
            </button>
            <a className="btn btn-sm btn-primary" onClick={onSaveHandler}>
              <span style={{ marginLeft: -4 }}>
                <KTSVG
                  path="/media/icons/check-circle.svg"
                  className="svg-icon-4 mt-n1"
                />
              </span>
              <FormattedMessage id="common.save" />
            </a>
          </div>
        </div>
      </div>
    </BaseLoading>
  );
};
