/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { useForceUpdate } from "../../../hooks";
import { aiApi } from "../../../api";
import { REQUEST_RETRY_LIMIT } from "../../../constants/constant";
import { PageTitle } from "../../../../_metronic/layout/core";
import { KTSVG } from "../../../../_metronic/helpers";
import { swalUtil } from "../../../utils/swalUtil";

export const CreateImage: FC = () => {
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  const formRef = useRef<any>();
  const cancellerRef = useRef<any>();
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  const [initValues] = useState<any>({ prompt: "" });
  const [loading, setLoading] = useState(false);
  const [generatedImageUrl, setGeneratedImageUrl] = useState<string>("");

  const schema = Yup.object({
    prompt: Yup.string().required().label("Prompt"),
  });

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
    return () => {
      if (typeof cancellerRef?.current?.abort === "function") {
        cancellerRef.current.abort();
      }
    };
  }, [stepperRef]);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInstance(
      stepperRef.current as HTMLDivElement
    );
  };

  const onGenerateImageHandler = () => {
    setLoading(true);
    setGeneratedImageUrl("");
    let retryCount = 0;

    (async function generate(): Promise<any> {
      try {
        retryCount++;
        const controller = new AbortController();
        cancellerRef.current = controller;
        const response = await aiApi.generateImage(
          { prompt: formRef.current.values.prompt, type: "custom-prompt" },
          controller.signal
        );

        setGeneratedImageUrl(response.data.image_url);
        setLoading(false);
      } catch (error: any) {
        if (error?.code === "ERR_CANCELED") {
          return;
        }

        if (retryCount < REQUEST_RETRY_LIMIT) {
          return generate();
        }

        setLoading(false);
        swalUtil.showErrorMessage(error);
      }
    })();
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
    forceUpdate();
  };

  const submitStep = () => {
    if (!stepper.current || stepper.current.currentStepIndex === 2) {
      navigate(-1);
      return;
    }

    stepper.current.goNext();
    forceUpdate();
    onGenerateImageHandler();
  };

  return (
    <>
      <PageTitle>
        <FormattedMessage id="image.generateImage" />
      </PageTitle>
      <div className="card">
        <div className="card-body">
          <div
            ref={stepperRef}
            className="stepper stepper-links d-flex flex-column pt-15"
            id="kt_generate_new_stepper"
          >
            <Formik
              innerRef={formRef}
              validationSchema={schema}
              initialValues={initValues}
              onSubmit={submitStep}
            >
              {() => (
                <Form
                  className={`mx-auto mw-600px w-100 pt-15 pb-10`}
                  id="kt_generate_new_form"
                >
                  <div className="current" data-kt-stepper-element="content">
                    <Step1 />
                  </div>

                  <div data-kt-stepper-element="content">
                    <Step2
                      generatedImageUrl={generatedImageUrl}
                      loading={loading}
                    />
                  </div>

                  <div className="d-flex flex-stack pt-10">
                    <div className="mr-2">
                      <button
                        onClick={prevStep}
                        type="button"
                        className="btn btn-lg btn-secondary me-3"
                        data-kt-stepper-action="previous"
                      >
                        <KTSVG
                          path="/media/icons/arrow-left.svg"
                          className="svg-icon-4 me-1"
                        />
                        <FormattedMessage id="common.back" />
                      </button>
                    </div>

                    <div className="d-flex gap-2">
                      {stepper.current?.currentStepIndex === 2 && (
                        <a
                          type="submit"
                          className="btn btn-lg btn-light-primary me-3"
                          onClick={onGenerateImageHandler}
                        >
                          <span className="indicator-label">
                            <FormattedMessage id="image.reGenerate" />
                          </span>
                        </a>
                      )}

                      <button
                        type="submit"
                        className="btn btn-lg btn-primary me-3"
                      >
                        <span className="indicator-label">
                          <FormattedMessage
                            id={
                              stepper.current?.currentStepIndex === 2
                                ? "common.close"
                                : "common.continue"
                            }
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};
