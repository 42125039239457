/* eslint-disable react/jsx-no-target-blank */
import { FormattedMessage, useIntl } from "react-intl";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";

const SidebarMenuMain = () => {
  const intl = useIntl();

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="/media/icons/graph.svg"
        title={intl.formatMessage({ id: "menu.dashboard" })}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItemWithSub
        to="/ai-powered"
        title={intl.formatMessage({ id: "menu.ai" })}
        fontIcon="bi-archive"
        icon="/media/icons/ai.svg"
      >
        <SidebarMenuItem
          to="/ai-powered/content/list"
          title={intl.formatMessage({ id: "menu.ai.contentList" })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/ai-powered/image/list"
          title={intl.formatMessage({ id: "menu.ai.images" })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            <FormattedMessage id="menu.ai.tools" />
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="/ai-powered/content/create"
        icon="/media/icons/writing.svg"
        title={intl.formatMessage({ id: "editor.content.create" })}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/ai-powered/image/create"
        icon="/media/icons/brush.svg"
        title={intl.formatMessage({ id: "image.generateImage" })}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/ai-powered/rephrase"
        icon="/media/icons/refresh-2.svg"
        title={intl.formatMessage({ id: "editor.rephrase" })}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/ai-powered/summarize"
        icon="/media/icons/summarize.svg"
        title={intl.formatMessage({ id: "editor.summarize" })}
        fontIcon="bi-app-indicator"
      />
    </>
  );
};

export { SidebarMenuMain };
