/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";

import { KTSVG } from "../../../../_metronic/helpers";
import { _intl } from "../../../../_metronic/layout/MasterInit";
import { ERROR_EVENT } from "../events";
import { getSocketConnection } from "../../../api/socketApi";
import { getTempEditor } from "../utils";
import { getAuth, getUserPreferences } from "../../../pages/auth";
import userApi from "../../../api/userApi";
import { swalUtil } from "../../../utils/swalUtil";

const View = ({ editorApi, blockData }) => {
  const socketRef = useRef();
  const displayContentRef = useRef();

  const [createdContent, setCreatedContent] = useState("");
  const [isFinish, setIsFinish] = useState(false);
  const [dots, setDots] = useState(".");

  useEffect(() => {
    createContent();

    return () => {
      if (typeof socketRef.current?.close === "function") {
        socketRef.current?.close();
      }
    };
  }, []);

  const createContent = async () => {
    try {
      const preferences = getUserPreferences();
      const socket = getSocketConnection();
      socketRef.current = socket;

      const dotsInterval = setInterval(() => {
        setDots((prevDots) => (prevDots.length >= 3 ? "." : prevDots + "."));
      }, 300);

      userApi.getCurrentUser().then((response) => {
        const subsEndDate = new Date(response.data.end_date);
        subsEndDate.setHours(23);
        subsEndDate.setMinutes(59);
        subsEndDate.setSeconds(59);
        const dateNow = new Date().getTime();
        if (dateNow > subsEndDate.getTime()) {
          socket.close();
          editorApi.blocks.delete(editorApi.blocks.getCurrentBlockIndex());
          swalUtil.licenseExpiredErrorMessage();
        } else if (response.data?.remaining_word_limit <= 0) {
          socket.close();
          editorApi.blocks.delete(editorApi.blocks.getCurrentBlockIndex());
          swalUtil.noLimitErrorMessage();
        }
      });

      socket.on("connect", (e) => {
        const { topic, socketEvent } = blockData.socketPayload;
        const event = socketEvent || "generate_text";
        socket.emit(event, {
          topic,
          language: preferences.default_language,
          access_token: getAuth().access_token,
        });
      });

      socket.on("message", (message) => {
        clearInterval(dotsInterval);
        setCreatedContent((prev) => {
          return prev + message;
        });
      });

      socket.on("disconnect", (event) => {
        socketRef.current = null;
        setIsFinish(true);
      });

      socket.on("error", (event) => {
        editorApi.blocks.delete(editorApi.blocks.getCurrentBlockIndex());
        editorApi.events.emit(ERROR_EVENT);
      });
    } catch (error) {
      editorApi.events.emit(ERROR_EVENT);
    }
  };

  const onOkHandler = async () => {
    const tempEditor = await getTempEditor();
    await tempEditor.blocks.renderFromHTML(createdContent);

    setTimeout(() => {
      tempEditor.save().then((data) => {
        editorApi.blocks.delete(editorApi.blocks.getCurrentBlockIndex());
        editorApi.blocks.insertMany(
          data.blocks,
          editorApi.blocks.getCurrentBlockIndex() + 1
        );
      });
    }, 150);
  };

  const onReCreateHandler = () => {
    setCreatedContent("");
    createContent();
  };

  return (
    <div className="w-full border rounded p-4 mb-2 align-items-center">
      {createdContent === "" && <div className="fs-1">{dots}</div>}
      <div
        ref={displayContentRef}
        dangerouslySetInnerHTML={{ __html: createdContent }}
      ></div>
      {isFinish && (
        <div className="d-flex gap-4 mt-10 align-items-center">
          <button className="btn btn-sm btn-primary" onClick={onOkHandler}>
            {_intl.formatMessage({ id: "editor.convertToBlocks" })}
          </button>
          <button
            className="btn btn-sm btn-secondary"
            onClick={onReCreateHandler}
          >
            <KTSVG path="/media/icons/refresh.svg" className="svg-icon" />
            {_intl.formatMessage({ id: "editor.reCreate" })}
          </button>
        </div>
      )}
    </div>
  );
};

export class SocketWriterBlock {
  viewRoot;
  static get toolbox() {
    return null;
  }

  constructor(params) {
    this.api = params.api;
    this.data = params.data;
  }

  render() {
    const container = document.createElement("div");
    this.viewRoot = createRoot(container);
    this.viewRoot.render(<View editorApi={this.api} blockData={this.data} />);

    return container;
  }

  save(blockContent) {
    return {
      url: blockContent.value,
    };
  }

  destroy() {
    setTimeout(() => {
      this.viewRoot.unmount();
    });
  }
}
