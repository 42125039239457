import { FC } from "react";
import { Editor } from "../../../components/Editor";
// import { TrendMagic } from "../../../components/TrendMagic";

export const CreateContent: FC = () => {
  return (
    <div className="d-flex gap-4">
      <div className="card w-100">
        <div className="card-body">
          <Editor />
        </div>
      </div>
      {/* <div
        className="card"
        style={{
          height: "calc(100vh - 150px)",
          overflowY: "auto",
          overflowX: "hidden",
          maxWidth: 425,
        }}
      >
        <div className="card-body p-0 h-100">
          <TrendMagic />
        </div>
      </div> */}
    </div>
  );
};
