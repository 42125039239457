import { FC } from "react";

import BaseLoading from "../../../components/BaseLoading";

const Step2: FC<any> = ({ generatedImageUrl, loading }) => {
  return (
    <BaseLoading loading={loading}>
      <div style={{ height: "50vh" }} className="w-100 mt-n20 text-center">
        {generatedImageUrl && (
          <img
            src={generatedImageUrl}
            style={{ height: "100%", objectFit: "contain" }}
            alt=""
          />
        )}
      </div>
    </BaseLoading>
  );
};

export default Step2;
