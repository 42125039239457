/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import MonthlyAnalysisCard from "./widgets/MonthlyAnalysisCard";
import DashboardProvider from "./DashboardProvider";
import RemainingUsageCard from "./widgets/RemainingUsageCard";
import PackageDetailCard from "./widgets/PackageDetailCard";
import { useNavigate } from "react-router-dom";
import { KTSVG } from "../../../_metronic/helpers";
import { PageActions, PageTitle } from "../../../_metronic/layout/core";

const Dashboard: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        <FormattedMessage id="menu.dashboard" />
      </PageTitle>
      <PageActions>
        <a
          className={`btn btn-primary fw-bold`}
          onClick={() => {
            navigate("/ai-powered/content/create");
          }}
        >
          <KTSVG
            path="/media/icons/writing.svg"
            className="svg-icon-2 svg-icon-gray-500 me-4"
          />
          <FormattedMessage id="common.startWriting" />
        </a>
      </PageActions>
      <DashboardProvider>
        <div className="m-auto">
          <div className="row">
            <div className="col-12 mb-6">
              <MonthlyAnalysisCard />
            </div>
            <div className="col-4">
              <PackageDetailCard />
            </div>
            <div className="col-8">
              <RemainingUsageCard />
            </div>
          </div>
        </div>
      </DashboardProvider>
    </>
  );
};

export default Dashboard;
