import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { _intl, navigateTo } from "../../_metronic/layout/MasterInit";
import MySwal from "../components/MySwal";

class SwalUtil {
  showErrorMessage = (error: any) => {
    const errorMessage = error?.response?.data?.error?.message;
    if (errorMessage === "licenseHasExpired") {
      return this.licenseExpiredErrorMessage();
    }

    if (["noLimitForImage", "noLimitForContent"].includes(errorMessage)) {
      return this.noLimitErrorMessage();
    }

    return this.genericErrorMessage();
  };

  genericErrorMessage = () => {
    return MySwal.fire({
      icon: "error",
      title: "Oops...",
      text: _intl.formatMessage({ id: "common.generalError" }),
      confirmButtonText: _intl.formatMessage({ id: "common.close" }),
      confirmButtonColor: getCSSVariableValue("--bs-primary"),
    });
  };

  licenseExpiredErrorMessage = () => {
    return MySwal.fire({
      icon: "error",
      title: _intl.formatMessage({ id: "common.licenseExpiredMessage.title" }),
      text: _intl.formatMessage({ id: "common.licenseExpiredMessage" }),
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonText: _intl.formatMessage({ id: "common.close" }),
      confirmButtonText: _intl.formatMessage({ id: "common.goToSubscription" }),
      confirmButtonColor: getCSSVariableValue("--bs-primary"),
    }).then((result) => {
      if (result.isConfirmed) {
        // go to subs page
        navigateTo("/subscription");
      }
    });
  };

  noLimitErrorMessage = () => {
    return MySwal.fire({
      icon: "error",
      title: _intl.formatMessage({ id: "common.noLimitMessage.title" }),
      text: _intl.formatMessage({ id: "common.noLimitMessage" }),
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonText: _intl.formatMessage({ id: "common.close" }),
      confirmButtonText: _intl.formatMessage({ id: "common.goToSubscription" }),
      confirmButtonColor: getCSSVariableValue("--bs-primary"),
    }).then((result) => {
      if (result.isConfirmed) {
        // go to subs page
        navigateTo("/subscription");
      }
    });
  };
}

const swalUtil = new SwalUtil();
export { swalUtil };
