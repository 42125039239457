import { AuthModel } from "./_models";

const AUTH_LOCAL_STORAGE_KEY = "besync_app_auth";
const USER_PREFERENCES_LOCAL_STORAGE_KEY = "besync_app_user_preferences";

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

const getUserPreferences = (): any | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(
    USER_PREFERENCES_LOCAL_STORAGE_KEY
  );
  if (!lsValue) {
    return;
  }

  try {
    const preferences: any = JSON.parse(lsValue);
    if (preferences) {
      return preferences;
    }
  } catch (error) {
    console.error("LOCAL STORAGE PARSE ERROR", error);
  }
};

const setUserPreferences = (preferences: any) => {
  if (!localStorage) {
    return;
  }

  try {
    let localVal = getUserPreferences();
    localVal = { ...localVal, ...preferences };
    const lsValue = JSON.stringify(localVal);
    localStorage.setItem(USER_PREFERENCES_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("LOCAL STORAGE SAVE ERROR", error);
  }
};

export {
  getAuth,
  setAuth,
  removeAuth,
  getUserPreferences,
  setUserPreferences,
  AUTH_LOCAL_STORAGE_KEY,
};
