import { Duration } from "luxon";
import { numberFormatter } from "../../../utils/numberUtil";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";

export const GENERATED_CONTENT_GROUP = "generatedContent";
export const PARAPHRASE_GROUP = "paraphrase";
export const IMAGE_CONTENT_GROUP = "imageContent";

export const GROUPS: any = [
  {
    id: GENERATED_CONTENT_GROUP,
    icon: "file.svg",
  },
  {
    id: PARAPHRASE_GROUP,
    icon: "refresh-2.svg",
  },
  {
    id: IMAGE_CONTENT_GROUP,
    icon: "image.svg",
  },
];

export const durationFormatter = (value: any, intl: any) => {
  const duration = Duration.fromObject({ second: value });
  const hours = duration.as("hours");
  if (hours < 1) {
    const minutes = duration.as("minutes");

    return `${numberFormatter({ number: minutes })} ${intl.formatMessage({
      id: "common.minutes",
    })}`;
  }
  return `${numberFormatter({ number: hours })} ${intl.formatMessage({
    id: "common.hours",
  })}`;
};

export const GROUPS_METRICS: any = {
  [GENERATED_CONTENT_GROUP]: [
    {
      intlId: "dashboard.generatedCount",
      dataPath: "generatedContent.articleCount",
      icon: "graph.svg",
    },
    {
      intlId: "dashboard.timeSaved",
      dataPath: "generatedContent.timeSaved",
      formatter: durationFormatter,
      icon: "hourglass.svg",
    },
    {
      intlId: "dashboard.wordsCount",
      dataPath: "generatedContent.wordCount",
      icon: "text-1.svg",
    },
  ],
  [PARAPHRASE_GROUP]: [
    {
      intlId: "dashboard.paraphrasedCount",
      dataPath: "paraphrase.articleCount",
      icon: "graph.svg",
    },
    {
      intlId: "dashboard.timeSaved",
      dataPath: "paraphrase.timeSaved",
      formatter: durationFormatter,
      icon: "hourglass.svg",
    },
    {
      intlId: "dashboard.wordsCount",
      dataPath: "paraphrase.wordCount",
      icon: "text-1.svg",
    },
  ],
  [IMAGE_CONTENT_GROUP]: [
    {
      intlId: "dashboard.imageCount",
      dataPath: "imageContent.imageCount",
      icon: "graph.svg",
    },
    {
      intlId: "dashboard.timeSaved",
      dataPath: "imageContent.timeSaved",
      formatter: durationFormatter,
      icon: "hourglass.svg",
    },
  ],
};

export const COLORS = ["#008FFB20", "#00E39620", "#775DD020"];

export const getChartOptions = (value: number, name: string) => {
  let color;

  if (value <= 20) {
    color = getCSSVariableValue("--bs-danger");
  } else if (value <= 40) {
    color = getCSSVariableValue("--bs-warning");
  }

  return {
    series: [value],
    chart: {
      fontFamily: "inherit",
      height: 200,
      width: 140,
      type: "radialBar",
      offsetY: -8,
      offsetX: -12,
    },
    colors: color ? [color] : [],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "55%",
        },
        dataLabels: {
          name: {
            show: true,
            fontWeight: "400",
            offsetY: -12,
            fontSize: "13px",
            color: getCSSVariableValue("--bs-text-gray-500"),
          },
          value: {
            color: getCSSVariableValue("--bs-text-gray-800"),
            fontSize: "26px",
            fontWeight: "500",
            offsetY: 2,
            show: true,
            formatter: function (val: any) {
              return val + "%";
            },
          },
        },
        track: {
          strokeWidth: "100%",
        },
      },
    },
    labels: [name],
    stroke: {
      lineCap: "round",
    },
  };
};
