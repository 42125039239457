/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { KTSVG } from "../../../_metronic/helpers";
import { useAuth } from "../auth";
import MySwal from "../../components/MySwal";
import { getCSSVariableValue } from "../../../_metronic/assets/ts/_utils";

const ShowLicenseCode = ({ currentUser, intl }: any) => {
  const buttonRef = useRef<any>();
  const [isClicked, setIsClicked] = useState(false);

  const onCopyClickHandler = () => {
    setIsClicked(true);
    navigator.clipboard.writeText(currentUser.license_code);
    setTimeout(() => {
      setIsClicked(false);
      buttonRef.current.blur();
    }, 3000);
  };

  return (
    <div className="d-flex align-items-center w-fit justify-center">
      <div
        className="bg-gray-100 p-4 fw-semibold rounded"
        style={{ lineHeight: 1.4, width: 270 }}
      >
        {currentUser?.license_code}
      </div>
      <button
        ref={buttonRef}
        className="btn btn-light btn-active-light-primary fw-bold my-4 w-125px"
        onClick={onCopyClickHandler}
      >
        <KTSVG
          path={
            isClicked
              ? "/media/icons/check-circle.svg"
              : "/media/icons/copy.svg"
          }
          className="svg-icon-1"
        />
        {intl.formatMessage({
          id: isClicked
            ? "wordPressPlugin.licenseCode.copied"
            : "wordPressPlugin.licenseCode.copy",
        })}
      </button>
    </div>
  );
};

const WordPressPlugin: FC = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();

  const getLicenseCode = async () => {
    MySwal.fire({
      title: (
        <h3>{intl.formatMessage({ id: "wordPressPlugin.yourLicense" })}</h3>
      ),
      text: "Something went wrong!",
      width: 500,
      html: <ShowLicenseCode currentUser={currentUser} intl={intl} />,
      confirmButtonText: intl.formatMessage({ id: "common.close" }),
      confirmButtonColor: getCSSVariableValue("--bs-primary"),
    });
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex h-100 justify-content-around">
          <div className="w-50 text-center mb-8">
            <div className="fs-1 fw-bold mt-6">
              <FormattedMessage id="wordPressPlugin.title" />
            </div>
            <div className="text-gray-800 p-8">
              <FormattedMessage id="wordPressPlugin.desc" />
            </div>
            <a
              href="https://besync.blob.core.windows.net/plugin/be-sync.zip"
              className="btn btn-sm btn-primary fw-semibold my-4"
              download
            >
              <KTSVG path="/media/icons/download.svg" className="svg-icon-3" />

              <FormattedMessage id="wordPressPlugin.download" />
            </a>

            <div className="fs-3 mt-8 mb-2">
              <FormattedMessage id="wordPressPlugin.howToUse" />
            </div>
            <ol
              style={{ width: "fit-content", display: "inline-block" }}
              className="text-start text-gray-700 px-15"
            >
              {[1, 2, 3, 4].map((i) => {
                return (
                  <li key={i}>
                    <FormattedMessage id={`wordPressPlugin.howToUse.${i}`} />
                  </li>
                );
              })}
            </ol>
          </div>
          <div className="w-1px bg-secondary my-2" />
          <div className="w-50 text-center">
            <div className="fs-1 fw-bold mt-6">
              <FormattedMessage id="wordPressPlugin.licenseCode.title" />
            </div>
            <div className="text-gray-800 p-8 w-75 m-auto p-8">
              <FormattedMessage id="wordPressPlugin.licenseCode.desc" />
            </div>
            <a
              className="btn btn-sm btn-primary fw-semibold my-4"
              onClick={getLicenseCode}
            >
              <FormattedMessage id="wordPressPlugin.getLicenseCode" />
            </a>

            <div className="fs-3 mt-8 mb-2">
              <FormattedMessage id="wordPressPlugin.howToUse" />
            </div>
            <ol
              style={{ width: "fit-content", display: "inline-block" }}
              className="text-start text-gray-700"
            >
              {[1, 2, 3].map((i) => {
                return (
                  <li key={i}>
                    <FormattedMessage
                      id={`wordPressPlugin.licenseCode.howToUse.${i}`}
                    />
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WordPressPlugin;
