/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { DateTime } from "luxon";
import { KTSVG } from "../../../../_metronic/helpers";
import MySwal from "../../../components/MySwal";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import { jobApi } from "../../../api";
import {
  systemMode,
  useThemeMode,
} from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import BaseLoading from "../../../components/BaseLoading";

const modalsRoot = document.getElementById("root-modals") || document.body;

const ImageDetailModal: FC<any> = ({
  isDetailModalVisible,
  selectedImage,
  onDetailModalVisibilityHandler,
  onDeleteHandler,
}) => {
  const intl = useIntl();
  const { mode } = useThemeMode();

  const [loading, setLoading] = useState<boolean>(false);
  const [imageData, setImageData] = useState<any>({});

  const _mode = useMemo(() => {
    return mode !== "system" ? mode : systemMode;
  }, [mode]);

  useEffect(() => {
    if (selectedImage.id) {
      fetchImageDetail();
    }
  }, [selectedImage]);

  const fetchImageDetail = async () => {
    try {
      setLoading(true);
      const response = await jobApi.getJobDetail({ job_id: selectedImage.id });
      setImageData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      onModalCloseHandler();
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: intl.formatMessage({ id: "common.generalError" }),
        confirmButtonText: intl.formatMessage({ id: "common.close" }),
        confirmButtonColor: getCSSVariableValue("--bs-primary"),
      });
    }
  };

  const onModalCloseHandler = () => {
    onDetailModalVisibilityHandler(false);
  };

  const boxShadow =
    _mode === "dark"
      ? "rgb(140 138 138 / 5%) 0px 0.1rem 0.75rem 0.25rem"
      : "0 0.1rem 0.75rem 0.25rem rgba(0, 0, 0, 0.05)";

  return createPortal(
    <Modal
      id="kt_modal_image_detail"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered min-w-1000px"
      show={isDetailModalVisible}
      onHide={onModalCloseHandler}
    >
      <div className="modal-header">
        <h2>
          <FormattedMessage id="image.detail" />
        </h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={onModalCloseHandler}
        >
          <KTSVG className="svg-icon-1" path="/media/icons/close.svg" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body py-lg-10 px-lg-10">
        <div className="d-flex flex-stack gap-4">
          <div></div>
          <img
            src={selectedImage.image_url}
            style={{
              height: "620px",
              objectFit: "contain",
              borderRadius: 8,
            }}
            alt=""
          />

          <div className="position-relative">
            <BaseLoading loading={loading}>
              <div
                className="d-flex flex-column p-6 rounded gap-2 w-300px"
                style={{ boxShadow }}
              >
                <div className="d-flex">
                  <span className="fw-bold text-gray-900 me-2">
                    <FormattedMessage id="image.prompt" />:
                  </span>
                  <span className="text-gray-800 text-wrap text-break">
                    {imageData.content || "-"}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fw-bold text-gray-900 me-2">
                    <FormattedMessage id="image.createdAt" />:
                  </span>
                  <span className="text-gray-800">
                    {imageData?.created_at
                      ? DateTime.fromISO(imageData?.created_at).toFormat(
                          "dd/MM/yy, HH:mm"
                        )
                      : "-"}
                  </span>
                </div>

                <div className="d-flex gap-4">
                  <a
                    href={selectedImage.image_url}
                    className="btn btn-sm btn-primary fw-semibold mt-4 w-100"
                    download
                  >
                    <KTSVG
                      path="/media/icons/download.svg"
                      className="svg-icon-3"
                    />

                    <FormattedMessage id="image.download" />
                  </a>
                  <a
                    className="btn btn-sm btn-danger fw-semibold mt-4 w-100"
                    onClick={() => {
                      onDeleteHandler(selectedImage.id, true);
                    }}
                  >
                    <KTSVG
                      path="/media/icons/delete.svg"
                      className="svg-icon-3"
                    />
                    <FormattedMessage id="image.delete" />
                  </a>
                </div>
              </div>
            </BaseLoading>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  );
};

export default ImageDetailModal;
