/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { StepperComponent } from "../../../../../_metronic/assets/ts/components";
import SignUpForm from "./SignUpForm";
import EmailVerify from "./EmailVerify";
import { useAuth } from "../../core/Auth";

const SignUp: FC = () => {
  const location = useLocation();
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  const { setTempPackage } = useAuth();

  useEffect(() => {
    stepper.current = StepperComponent.createInstance(
      stepperRef.current as HTMLDivElement
    );

    const searchParams = new URLSearchParams(location.search.slice(1));
    const packageId = Number(searchParams.get("package"));
    const packagePeriod = Number(searchParams.get("period"));
    if ([2, 3, 4, 5].includes(packageId)) {
      setTempPackage({ id: packageId, period: packagePeriod });
    }
  }, []);

  const goNext = useCallback(() => {
    stepper.current?.goNext();
  }, []);

  return (
    <div
      ref={stepperRef}
      className="stepper stepper-links d-flex flex-column"
      id="kt_generate_new_stepper"
    >
      {/* MARK: - Step1 */}
      <div className="current" data-kt-stepper-element="content">
        <SignUpForm goNext={goNext} />
      </div>

      {/* MARK: - Step2 */}
      <div data-kt-stepper-element="content">
        <EmailVerify />
      </div>
    </div>
  );
};

export default SignUp;
