/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TrendMagicOptions } from "./Steps/TrendMagicOptions";
import { ByTrends } from "./Steps/ByTrends";
import { FinallyStep } from "./Steps/FinallyStep";
import { ByKeywords } from "./Steps/ByKeywords";
import { ByLinks } from "./Steps/ByLinks";
import { SelectMainTitle } from "./Steps/SelectMainTitle";
import { SelectSubTitle } from "./Steps/SelectSubTitle";

export const MOCK_CONTENT = `<h1>Tesla Cybertruck Features</h1><br />
<h2>1. Exoskeleton Design</h2>
One of the most striking features of the Tesla Cybertruck is its exoskeleton design. Made of ultra-hard 30X cold-rolled stainless steel, this electric pickup truck is built to withstand extreme conditions and provide unparalleled durability. The exoskeleton design not only enhances the vehicle's strength but also reduces the overall weight, resulting in improved performance and efficiency.
<br /><br /><h3>2. Armored Glass</h3>
Tesla has equipped the Cybertruck with armored glass windows, adding an extra layer of security to the vehicle. The transparent metal glass composite is designed to be tough and resistant to impact, providing occupants with a sense of safety and peace of mind.
<br /><br /><h3>3. Electric Powertrain</h3>
As with all Tesla vehicles, the Cybertruck features an electric powertrain that offers impressive performance and efficiency. It is equipped with a tri-motor all-wheel-drive system, delivering exceptional acceleration and torque. With a range of up to 500 miles on a single charge, the Cybertruck sets a new standard for electric vehicles in terms of range and capability.
<br /><br /><h2>4. Versatile Utility</h2>
The Cybertruck is designed to be a truly versatile utility vehicle. With its spacious interior and flexible storage solutions, it can easily accommodate both passengers and cargo. The rear bed offers a large cargo space, and the vault-like tonneau cover provides secure storage for valuable items. Additionally, the Cybertruck comes with adjustable air suspension, allowing users to customize the ride height based on their needs.
<br /><br /><h3>5. Autopilot and Full Self-Driving</h3>
Tesla is renowned for its advanced autopilot and full self-driving capabilities, and the Cybertruck is no exception. Equipped with a suite of sensors and cameras, it offers features such as adaptive cruise control, lane-keeping assist, and automatic emergency braking. The Cybertruck also has the potential to achieve full self-driving capabilities in the future through over-the-air software updates.
<br /><br /><h3>6. Innovative User Interface</h3>
The Cybertruck features a minimalist and futuristic user interface that is characteristic of Tesla vehicles. The 17-inch touchscreen display provides access to various vehicle settings, navigation, entertainment, and more. The user interface is intuitive and user-friendly, ensuring a seamless driving experience.
<br /><br /><h2>7. Off-Road Capability</h2>
With its robust design and powerful electric drivetrain, the Cybertruck is built to tackle off-road adventures. It boasts an impressive ground clearance and approach/departure angles, allowing it to conquer challenging terrains with ease. The vehicle's adaptive air suspension further enhances its off-road capability by adjusting the ride height to navigate obstacles.
<br /><br /><h2>8. Range</h2>
The rear-wheel drive variant of the vehicle boasts an impressive estimated range of 250 miles. Meanwhile, the all-wheel drive and "Cyberbeast" editions take it a step further, offering a range of 340 miles and 320 miles respectively.
But wait, there's more!
The top two models can even surpass these numbers with the optional range extender. This nifty addition, occupying approximately one-third of the truck's bed space, enables the vehicle to conquer lengthy journeys and effortlessly tow hefty loads up treacherous mountains. As the visionary CEO Elon Musk eloquently puts it, this truck is designed to tackle the longest of voyages and conquer the most demanding of terrains.
<br /><br /><h2>9. Power</h2>
Introducing Tesla's revolutionary "Cyberbeast" Cybertruck, a true marvel of engineering and performance. With mind-boggling acceleration, this beast can go from 0 to 60 mph in an astonishing 2.6 seconds, leaving even the fastest sports cars in its dust.
In a captivating display,
Elon Musk himself unveiled a video showcasing the Cybertruck effortlessly outpacing a Porsche 911 in a thrilling drag race, all while effortlessly towing another unit of the very same sports car. But that's not all.
This awe-inspiring machine
boasts an impressive towing capacity of 11,000 pounds, putting it on par with Rivian's R1T and surpassing the likes of the Ford F-150 Lightning and GM Silverado EV, which can only manage 10,000 pounds. Prepare to be astounded by the sheer power and innovation of Tesla's Cyberbeast Cybertruck!
<br /><br /><h2>In conclusion</h2>
In conclusion, the Tesla Cybertruck offers a range of innovative features that make it a game-changer in the electric vehicle industry. From its exoskeleton design and armored glass to its electric powertrain and versatile utility, the Cybertruck pushes the boundaries of what an electric pickup truck can achieve. With its advanced autopilot capabilities and futuristic user interface, it provides a truly unique and exhilarating driving experience. Whether you're a fan of electric vehicles or simply appreciate cutting-edge technology, the Tesla Cybertruck is undoubtedly a vehicle worth considering.
`;

const STEPS = {
  trendMagicOption: "trendMagicOption",
  byTrends: "byTrends",
  byKeywordsAndLinks: "byKeywordsAndLinks",
  byKeywords: "byKeywords",
};

const STEP_COMPONENTS: any = {
  trendMagicOption: TrendMagicOptions,
  byTrends: ByTrends,
  byKeywordsAndLinks: ByLinks,
  byKeywords: ByKeywords,
  selectMainTitle: SelectMainTitle,
  selectSubTitle: SelectSubTitle,
  finallyStep: FinallyStep,
};

export const TrendMagic = () => {
  const [stepHistory, setStepHistory] = useState<any>([]);
  const [currentStep, setCurrentStep] = useState(STEPS.trendMagicOption);

  useEffect(() => {
    if (stepHistory.includes(currentStep)) {
      stepHistory.pop();
      setStepHistory([...stepHistory]);
    } else {
      setStepHistory([...stepHistory, currentStep]);
    }
  }, [currentStep]);

  return (
    <div className="trend-magic p-6">
      <div className="fs-2 mb-5">Trend Magic</div>
      <div className="d-flex flex-column gap-16">
        {stepHistory.map((step: string, index: number) => {
          const StepComponent = STEP_COMPONENTS[step];
          return (
            <React.Fragment key={step}>
              <StepComponent
                stepHistory={stepHistory}
                step={currentStep}
                setStep={setCurrentStep}
              />
              {stepHistory.length - 1 !== index && (
                <div className="separator separator-dotted mx-n6" />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
