/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Editor } from "../../../components/Editor";
import documentApi from "../../../api/documentApi";
import MySwal from "../../../components/MySwal";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";

export const EditContent: FC = () => {
  const intl = useIntl();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    fetchContentDetail();
  }, []);

  const fetchContentDetail = async () => {
    try {
      setIsLoading(true);
      const response = await documentApi.getDocument({
        document_id: params.id,
      });

      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: intl.formatMessage({ id: "common.generalError" }),
        confirmButtonText: intl.formatMessage({ id: "common.close" }),
        confirmButtonColor: getCSSVariableValue("--bs-primary"),
      });
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <Editor loading={isLoading} initialData={data} documentId={params.id} />
      </div>
    </div>
  );
};
