const GENERATED_CONTENT = "generated_content";
const IMAGE_CONTENT = "image_content";
const PARAPHRASE = "paraphrase";

const jobTypes = {
  GENERATED_CONTENT,
  PARAPHRASE,
  IMAGE_CONTENT,
};

export default jobTypes;
