import { FC } from "react";
import Select from "react-select";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";

export const BaseSelect: FC<any> = ({
  options,
  value,
  onChange,
  placeholder,
  ...rest
}) => {
  return (
    <Select
      styles={{
        control: (baseStyles, state) => {
          return {
            ...baseStyles,
            "&:hover": {
              borderColor: "transparent",
            },
            boxShadow: "none",
            borderColor: state.isFocused
              ? getCSSVariableValue("--bs-gray-200")
              : getCSSVariableValue("--bs-gray-100"),
            backgroundColor: state.isFocused
              ? getCSSVariableValue("--bs-gray-200")
              : getCSSVariableValue("--bs-gray-100"),
            paddingTop: 2,
            paddingBottom: 2,
            cursor: "pointer",
          };
        },
        option: (base) => {
          return {
            ...base,
            ":active": {
              backgroundColor: getCSSVariableValue("--bs-gray-200"),
            },
            cursor: "pointer",
          };
        },

        menu: (base) => {
          return {
            ...base,
            backgroundColor: getCSSVariableValue("--bs-body-bg"),
          };
        },
        input: (base) => {
          return {
            ...base,
            color: getCSSVariableValue("--bs-gray-700"),
            fontSize: "1.15rem",
            paddingLeft: 8,
          };
        },
        singleValue: (base) => {
          return {
            ...base,
            color: getCSSVariableValue("--bs-gray-700"),
            fontSize: "1.15rem",
            paddingLeft: 8,
          };
        },
        placeholder(base, props) {
          return {
            ...base,
            color: getCSSVariableValue("--bs-gray-500"),
            fontSize: "1.15rem",
            paddingLeft: 8,
          };
        },
      }}
      isSearchable
      options={options}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: getCSSVariableValue("--bs-gray-200"),
          primary: getCSSVariableValue("--bs-primary"),
        },
      })}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      {...rest}
    />
  );
};
