/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, FC } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { PasswordMeterComponent } from "../../../../../_metronic/assets/ts/components";
import userApi from "../../../../api/userApi";
import { useAuth } from "../../core/Auth";

const initialValues = {
  email: "",
  password: "",
  confirmPassword: "",
};

const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Minimum 8 characters")
    .max(16, "Maximum 16 symbols")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
});

const SignUpForm: FC<any> = ({ goNext }) => {
  const intl = useIntl();
  const { setTempEmail } = useAuth();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        await userApi.signUp(values.email, values.password);
        setTempEmail(values.email);
        goNext();
      } catch (error) {
        setStatus("The registration details is incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className="mb-10 text-center">
        {/* begin::Title */}
        <h1 className="text-dark mb-3">
          <FormattedMessage id="auth.createAccount" />
        </h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Email */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bold text-dark fs-6">
          <FormattedMessage id="auth.email" />
        </label>
        <input
          placeholder={intl.formatMessage({ id: "auth.email" })}
          type="email"
          autoComplete="off"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className="mb-10 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bold text-dark fs-6">
            <FormattedMessage id="auth.password" />
          </label>
          <div className="position-relative mb-3">
            <input
              type="password"
              placeholder={intl.formatMessage({ id: "auth.password" })}
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-muted">
          <FormattedMessage id="auth.passwordMeterDesc" />
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-10">
        <label className="form-label fw-bold text-dark fs-6">
          <FormattedMessage id="auth.confirmPassword" />
        </label>
        <input
          type="password"
          placeholder={intl.formatMessage({
            id: "auth.passwordConfirmation",
          })}
          autoComplete="off"
          {...formik.getFieldProps("confirmPassword")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid":
                formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              "is-valid":
                formik.touched.confirmPassword &&
                !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label">
              {" "}
              <FormattedMessage id="auth.signUp" />{" "}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              <FormattedMessage id="common.pleaseWait" />{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light w-100 mb-5"
          >
            <FormattedMessage id="common.cancel" />
          </button>
        </Link>
      </div>
      {/* end::Form group */}

      {/* begin::Link */}
      <div className="text-center text-gray-400 fw-semibold fs-4">
        <FormattedMessage id="auth.alreadyHaveAccount" />
        <Link
          to="/auth/login"
          className="link-primary fw-bold"
          style={{ marginLeft: "5px" }}
        >
          <FormattedMessage id="auth.login" />
        </Link>
      </div>
      {/* end::Link */}
    </form>
  );
};

export default SignUpForm;
