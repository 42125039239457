export function generateUniqueId() {
  const crypto = window.crypto || window.Crypto;
  var array = new Uint32Array(2);
  const id = crypto.getRandomValues(array);
  return id.join("-");
}

export const countWords = (str: string) => {
  const clearedStr = str?.trim();
  if (clearedStr == null || clearedStr === "") {
    return 0;
  }

  // Split the string into an array of words
  const words = clearedStr.split(/\s+/);

  // Return the count of words
  return words.length;
};

export const countLetters = (str: string) => {
  // Remove spaces from the string
  const stringWithoutSpaces = str.replace(/\s/g, "");

  // Return the count of letters
  return stringWithoutSpaces.length;
};

export const clearHtmlTags = (htmlString: string) => {
  if (htmlString == null || htmlString === "") {
    return "";
  }

  // Create a regular expression to match HTML tags
  const regex = /<[^>]+>/g;

  // Remove HTML tags from the string
  const clearedString = htmlString?.replace(regex, " ");

  return clearedString;
};
