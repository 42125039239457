/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import packagesApi from "../../api/packagesApi";
import { KTSVG } from "../../../_metronic/helpers";
import BaseLoading from "../../components/BaseLoading";
import { useAuth } from "../auth";
import userApi from "../../api/userApi";
import MySwal from "../../components/MySwal";
import { getCSSVariableValue } from "../../../_metronic/assets/ts/_utils";
import subscriptionApi from "../../api/subscriptionApi";
import { getPackageContents } from "./utils";
import { useSetState } from "../../hooks";

const getDefaultPackagePeriod = (packageId: any) => {
  return packageId > 3 ? "yearly" : "monthly";
};

const Subscription: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { currentUser, tempPackage, setCurrentUser } = useAuth();

  const [documentVisibility, setDocumentVisibility] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(
    tempPackage?.id || currentUser?.current_package_id || 1
  );
  const [packagePeriod, setPackagePeriod] = useState<any>(
    tempPackage?.period ||
      getDefaultPackagePeriod(currentUser?.current_package_id)
  );
  const [collapse, setCollapse] = useSetState<any>({});

  useEffect(() => {
    fetchPackages();

    const visibilityChangeHandler = () => {
      setDocumentVisibility(document.visibilityState);
    };
    document.addEventListener("visibilitychange", visibilityChangeHandler);

    return () => {
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  }, []);

  useEffect(() => {
    if (documentVisibility === "visible") {
      fetchCurrentUser();
    }
  }, [documentVisibility]);

  const fetchPackages = async () => {
    try {
      setLoading(true);
      const response = await packagesApi.getPackages();
      setPackages(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: intl.formatMessage({ id: "common.generalError" }),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: intl.formatMessage({ id: "common.cancel" }),
        confirmButtonText: intl.formatMessage({ id: "common.tryAgin" }),
        confirmButtonColor: getCSSVariableValue("--bs-primary"),
      }).then((result) => {
        if (result.isConfirmed) {
          fetchPackages();
        } else {
          navigate("/dashboard");
        }
      });
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const response = await userApi.getCurrentUser();
      const newCurrentUser = response.data;

      if (
        newCurrentUser.current_package_id !== currentUser?.current_package_id
      ) {
        MySwal.fire({
          icon: "success",
          title: intl.formatMessage({ id: "subscription.buy.success.title" }),
          text: intl.formatMessage({ id: "subscription.buy.success.desc" }),
          didClose: () => {
            navigate("/dashboard");
          },
          confirmButtonText: intl.formatMessage({
            id: "common.close",
          }),
          confirmButtonColor: getCSSVariableValue("--bs-primary"),
        });

        setCurrentUser(newCurrentUser);
      }
    } catch (error) {
      // error
    }
  };

  const onBuyClickHandler = async () => {
    if (selectedPackage === 1) {
      return;
    }

    MySwal.showLoading();
    const response = await subscriptionApi.createPaymentLink(selectedPackage);
    window.open(response.data.link, "_blank");
    MySwal.close();
  };

  const getButtonIntlId = () => {
    if (selectedPackage === 1) {
      return "subscription.buy";
    }

    if (selectedPackage === currentUser?.current_package_id) {
      return "subscription.buyAgain";
    }

    if (selectedPackage === 3 && currentUser?.current_package_id === 2) {
      return "subscription.upgrade";
    }

    return "subscription.buy";
  };

  return (
    <div className="card">
      <div className="card-body min-h-300px">
        {loading ? (
          <BaseLoading loading={loading}>
            <div className="h-300px" />
          </BaseLoading>
        ) : (
          packages.length > 0 && (
            <div className="row align-items-center justify-content-center">
              <div className="text-center mb-10 fs-2x fw-semibold ">
                <FormattedMessage id="subscription.title" />
              </div>
              <div className="d-flex mb-8">
                <div className="nav-group nav-group-outline mx-auto">
                  <button
                    className={clsx(
                      "btn btn-color-gray-500 btn-active btn-active-secondary px-6 py-3 me-2",
                      packagePeriod === "monthly" ? "active" : ""
                    )}
                    onClick={() => {
                      setPackagePeriod("monthly");
                      if (selectedPackage === 4) {
                        setSelectedPackage(2);
                      }
                      if (selectedPackage === 5) {
                        setSelectedPackage(3);
                      }
                    }}
                  >
                    <FormattedMessage id="subscription.monthly" />
                  </button>
                  <button
                    className={clsx(
                      "btn btn-color-gray-500 btn-active-secondary px-6 py-3 me-2",
                      packagePeriod === "yearly" ? "active" : ""
                    )}
                    onClick={() => {
                      setPackagePeriod("yearly");
                      if (selectedPackage === 2) {
                        setSelectedPackage(4);
                      }
                      if (selectedPackage === 3) {
                        setSelectedPackage(5);
                      }
                    }}
                  >
                    <FormattedMessage id="subscription.annually" />
                  </button>
                </div>
              </div>
              <div className="mb-10 mb-lg-0">
                <div className="nav d-flex justify-content-center">
                  {packages
                    .filter((p: any) => {
                      if (p.id === 1) {
                        return true;
                      }
                      return p.period === packagePeriod;
                    })
                    .map((p: any, index) => {
                      const price =
                        packagePeriod === "monthly"
                          ? p.price
                          : (p.price / 12).toFixed(2);
                      const [priceNumberPart, priceDecimalPart] = Number(price)
                        .toString()
                        .split(".");
                      const withActive =
                        selectedPackage === p.id ? "active" : "";
                      return (
                        <div key={p.id} style={{ width: 350 }}>
                          <div
                            className={clsx(
                              "nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 h-90px",
                              withActive
                            )}
                            style={{ marginLeft: 12 }}
                            onClick={() => {
                              setSelectedPackage(p.id);
                            }}
                          >
                            <div className="d-flex align-items-center me-2">
                              <div className="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="plan"
                                  checked={selectedPackage === p.id}
                                  onChange={() => {
                                    setSelectedPackage(p.id);
                                  }}
                                />
                              </div>
                              <div className="flex-grow-1">
                                <div className="d-flex algin-items-center fs-2 fw-bold flex-wrap">
                                  {p.package_name}
                                </div>
                              </div>
                            </div>
                            <div className="ms-5">
                              {p.price != null && p.price !== 0 ? (
                                <>
                                  <span className="mt-4 fs-2x me-1">$</span>
                                  <span className="fs-3x fw-bold me-1">
                                    {priceNumberPart}
                                    {priceDecimalPart != null && (
                                      <span className="fs-2">
                                        .{priceDecimalPart}
                                      </span>
                                    )}
                                  </span>
                                  <span className="fs-7 opacity-50">
                                    /
                                    <span>
                                      <FormattedMessage id="subscription.period.monthly" />
                                    </span>
                                  </span>
                                </>
                              ) : (
                                <span className="fs-2x fw-bold">
                                  <FormattedMessage id="subscription.free" />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="d-flex mt-4 justify-content-center">
                {packages
                  .filter((p: any) => {
                    if (p.id === 1) {
                      return true;
                    }
                    return p.period === packagePeriod;
                  })
                  .map((p: any) => {
                    const packageContents = getPackageContents(p.id);
                    return (
                      <div key={p.id} style={{ width: 355 }}>
                        <div
                          style={{ marginLeft: 12, minHeight: 480 }}
                          className="rounded bg-light px-6 py-1"
                        >
                          <div className="pb-5 mt-6">
                            <h2 className="fw-bold text-dark">
                              <FormattedMessage
                                id="subscription.package.whatIsPackage"
                                values={{ packageName: p.package_name }}
                              />
                            </h2>
                          </div>

                          <div className="d-flex flex-column gap-4">
                            {packageContents
                              .slice(
                                0,
                                collapse[p.id] ? packageContents.length : 6
                              )
                              .map((intlId, idx) => {
                                return (
                                  <div key={idx} className="">
                                    <div className="d-flex align-items-center gap-2">
                                      <KTSVG
                                        path="/media/icons/check-circle.svg"
                                        className="svg-icon-2x text-success"
                                      />
                                      <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                        <FormattedMessage id={intlId} />
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <a
                            className="d-flex justify-content-center mt-1"
                            onClick={() => {
                              setCollapse({
                                [p.id]: !collapse[p.id],
                              });
                            }}
                          >
                            <KTSVG
                              path={
                                collapse[p.id]
                                  ? "/media/icons/arrow-up-2.svg"
                                  : "/media/icons/arrow-down-2.svg"
                              }
                              className="svg-icon-3x cursor-pointer"
                            />
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {selectedPackage !== 1 &&
                selectedPackage === currentUser?.current_package_id && (
                  <span className="text-center mt-10 text-gray-700">
                    <FormattedMessage id="subscription.buyAgain.note" />
                  </span>
                )}
              <div className="d-flex justify-content-center mt-10">
                <button
                  className="btn btn-primary w-130px"
                  disabled={selectedPackage === 1}
                  onClick={onBuyClickHandler}
                >
                  <FormattedMessage id={getButtonIntlId()} />
                </button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Subscription;
