/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import trim from "lodash/trim";
import documentApi from "../../../api/documentApi";
import MySwal from "../../../components/MySwal";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import { PageActions, PageTitle } from "../../../../_metronic/layout/core";
import { KTSVG } from "../../../../_metronic/helpers";
import { BaseTable } from "../../../components";
import { createColumns } from "./columns";

export const ContentList: FC<any> = ({ pageTitle, pageActions, listType }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const pureDataRef = useRef([]);
  const searchTermRef = useRef("");

  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      setLoading(true);
      const response = await documentApi.getAllDocuments();
      const _data = response.data.sort((a: any, b: any) => {
        const aDate = new Date(a.updated_at);
        const bDate = new Date(b.updated_at);
        return aDate.getTime() > bDate.getTime() ? -1 : 1;
      });
      pureDataRef.current = _data;
      setData(_data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError();
    }
  };

  const showError = () => {
    MySwal.fire({
      icon: "error",
      title: "Oops...",
      text: intl.formatMessage({ id: "common.generalError" }),
      confirmButtonText: intl.formatMessage({ id: "common.close" }),
      confirmButtonColor: getCSSVariableValue("--bs-primary"),
    });
  };

  const onSelectChange = (newSelectedRowKeys: []) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onEditHandler = (id: number) => {
    navigate(`/ai-powered/content/edit/${id}`);
  };

  const onDeleteHandler = (id: number) => {
    MySwal.fire({
      title: intl.formatMessage({ id: "common.delete.confirm" }),
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: intl.formatMessage({ id: "common.cancel" }),
      confirmButtonText: intl.formatMessage({ id: "common.delete" }),
      confirmButtonColor: getCSSVariableValue("--bs-danger"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          MySwal.showLoading();
          await documentApi.deleteDocument({ document_id: id });
          setData((prevData: any) => {
            const newData = prevData.filter((p: any) => {
              return p.id !== id;
            });
            pureDataRef.current = newData;
            return newData;
          });

          MySwal.fire({
            icon: "success",
            title: intl.formatMessage({ id: "common.delete.success" }),
            confirmButtonText: intl.formatMessage({ id: "common.close" }),
            confirmButtonColor: getCSSVariableValue("--bs-primary"),
          });
        } catch (error) {
          showError();
        }
      }
    });
  };

  const deleteSelectedHandler = () => {
    MySwal.fire({
      title: intl.formatMessage({ id: "common.delete.confirm" }),
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: intl.formatMessage({ id: "common.cancel" }),
      confirmButtonText: intl.formatMessage({ id: "common.delete" }),
      confirmButtonColor: getCSSVariableValue("--bs-danger"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          MySwal.showLoading();
          const allTasks = selectedRowKeys.map((id) => {
            return documentApi.deleteDocument({ document_id: id });
          });
          await Promise.all(allTasks);

          setData((prevData: any) => {
            const newData = prevData.filter((p: any) => {
              return !selectedRowKeys.includes(p.id);
            });
            pureDataRef.current = newData;
            return newData;
          });

          MySwal.fire({
            icon: "success",
            title: intl.formatMessage({ id: "common.delete.success" }),
            confirmButtonText: intl.formatMessage({ id: "common.close" }),
            confirmButtonColor: getCSSVariableValue("--bs-primary"),
          });
        } catch (error) {
          showError();
          fetchList();
        } finally {
          setSelectedRowKeys([]);
        }
      }
    });
  };

  const onSearchTermChange = (text: string) => {
    const searchTerm = trim(text).toLowerCase();

    searchTermRef.current = searchTerm;

    const filtered = pureDataRef.current.filter((d: any) => {
      const title = d.title?.toLowerCase();
      return title.includes(searchTerm);
    });
    setData(filtered);
  };

  const columns = useMemo(() => {
    return createColumns(onDeleteHandler, onEditHandler);
  }, []);

  return (
    <>
      <PageTitle>
        <FormattedMessage id="menu.ai.contentList" />
      </PageTitle>
      <PageActions>
        <a
          className="btn btn-primary"
          onClick={() => {
            navigate("/ai-powered/content/create");
          }}
        >
          <KTSVG
            path="/media/icons/writing.svg"
            className="svg-icon-2 svg-icon-gray-500 me-4"
          />
          <FormattedMessage id="editor.content.create" />
        </a>
      </PageActions>

      <div className="card">
        <div className="card-body">
          <div className="card-title d-flex justify-content-between mb-6">
            {/* MARK: - Search */}
            <div className="d-flex align-items-center position-relative my-1">
              <KTSVG
                path="/media/icons/search.svg"
                className="svg-icon-1 position-absolute ms-6"
              />
              <input
                type="text"
                className="form-control form-control-solid w-250px ps-14"
                placeholder={intl.formatMessage({ id: "common.search" })}
                onChange={(e) => onSearchTermChange(e.target.value)}
              />
            </div>

            {/* MARK: - Delete Selected Button */}
            {selectedRowKeys.length > 0 && (
              <div className="d-flex justify-content-end align-items-center">
                <div className="fw-bold me-5">
                  <span className="me-2">{selectedRowKeys.length}</span>{" "}
                  <FormattedMessage id="common.selected" />
                </div>

                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={deleteSelectedHandler}
                >
                  <KTSVG
                    path="/media/icons/delete.svg"
                    className="svg-icon-3"
                  />
                  <FormattedMessage id="common.deleteSelected" />
                </button>
              </div>
            )}
          </div>

          {/* MARK: - Table */}
          <BaseTable
            loading={loading}
            data={data}
            columns={columns}
            rowSelection={{
              onChange: onSelectChange,
              selectedRowKeys,
            }}
            rowKey="id"
          />
        </div>
      </div>
    </>
  );
};
