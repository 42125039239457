import httpClient from "./httpClient";

const getPackageById = (id: number) => {
  return httpClient.get(`/packages/${id}`);
};

const getPackages = () => {
  return httpClient.get("/packages/");
};

const packagesApi = {
  getPackageById,
  getPackages,
};

export default packagesApi;
