import httpClient from "./httpClient";

const getStatistics = () => {
  return httpClient.get("/statistics/");
};

const statisticsApi = {
  getStatistics,
};

export default statisticsApi;
