import { _intl } from "../../../../_metronic/layout/MasterInit";
import { ERROR_EVENT } from "../events";
import { CUSTOM_PROMPT_ICON } from "../Icons";
import { createTuneItem } from "../utils";

export class CustomPromptTune {
  static get isTune() {
    return true;
  }

  constructor({ api }) {
    this.api = api;
  }

  getCurrentBlock = () => {
    const currentIndex = this.api.blocks.getCurrentBlockIndex();
    const currentBlock = this.api.blocks.getBlockByIndex(currentIndex);

    return [currentBlock, currentIndex];
  };

  isDisabled = () => {
    const [currentBlock] = this.getCurrentBlock();
    return currentBlock.isEmpty;
  };

  onActivateHandler = async () => {
    try {
      const [currentBlock, currentIndex] = this.getCurrentBlock();
      const content = currentBlock.holder.innerText;

      this.api.blocks.delete(currentIndex);
      this.api.blocks.insert(
        "socketWriterBlock",
        { socketPayload: { topic: content, socketEvent: "generate_custom_prompt" } },
        undefined,
        currentIndex
      );
    } catch (error) {
      this.api.events.emit(ERROR_EVENT);
    }
  };

  render() {
    return createTuneItem({
      label: _intl.formatMessage({ id: "editor.customPrompt" }),
      icon: CUSTOM_PROMPT_ICON,
      isDisable: this.isDisabled(),
      onActivate: this.onActivateHandler,
    });
  }
}
