import { useState } from "react";
import { createRoot } from "react-dom/client";

import { KTSVG } from "../../../../_metronic/helpers";
import { _intl } from "../../../../_metronic/layout/MasterInit";
import MySwal from "../../MySwal";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import { aiApi } from "../../../api";
import { LOADING_END_EVENT, LOADING_START_EVENT } from "../events";
import { CREATE_IMAGE_ICON } from "../Icons";
import { swalUtil } from "../../../utils/swalUtil";

const View = ({ editorApi }) => {
  const [prompt, setPrompt] = useState();

  const createImage = async () => {
    try {
      editorApi.events.emit(LOADING_START_EVENT);
      const currentIndex = editorApi.blocks.getCurrentBlockIndex();

      if (prompt == null || prompt === "") {
        editorApi.events.emit(LOADING_END_EVENT);
        MySwal.fire({
          icon: "warning",
          title: _intl.formatMessage({ id: "editor.image.promptWarning" }),
          confirmButtonText: _intl.formatMessage({ id: "common.close" }),
          confirmButtonColor: getCSSVariableValue("--bs-primary"),
        });
        return;
      }

      const response = await aiApi.generateImage({
        prompt: prompt,
        type: "custom-prompt",
      });
      editorApi.blocks.insert("image", {
        file: {
          url: response.data.image_url,
        },
      });
      editorApi.blocks.delete(currentIndex);
      editorApi.events.emit(LOADING_END_EVENT);
    } catch (error) {
      editorApi.events.emit(LOADING_END_EVENT);
      swalUtil.showErrorMessage(error);
    }
  };

  return (
    <div className="w-full border rounded py-6 px-4 mb-2">
      <textarea
        className="form-control form-control-solid resize-none my-4 disabled"
        placeholder={_intl.formatMessage({ id: "editor.promptWithEllipsis" })}
        rows={3}
        onChange={(e) => {
          setPrompt(e.target.value);
        }}
      />
      <div className="d-flex justify-content-center mt-6">
        <button
          className={`btn btn-sm btn-primary fw-bold`}
          onClick={createImage}
        >
          <KTSVG path="/media/icons/brush.svg" className="svg-icon" />
          {_intl.formatMessage({ id: "editor.image.create" })}
        </button>
      </div>
    </div>
  );
};

export class CreateImageBlock {
  static get toolbox() {
    return {
      title: _intl.formatMessage({ id: "editor.image.create" }),
      icon: CREATE_IMAGE_ICON,
    };
  }

  constructor({ api }) {
    this.api = api;
  }

  render() {
    const container = document.createElement("div");
    createRoot(container).render(<View editorApi={this.api} />);

    return container;
  }

  save(blockContent) {
    return {
      url: blockContent.value,
    };
  }
}
