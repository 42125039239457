/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import _get from "lodash/get";
import CountUp from "react-countup";
import Chart from "../../../components/Chart";
import { useDashboardContext } from "../DashboardProvider";
import {
  COLORS,
  GENERATED_CONTENT_GROUP,
  GROUPS,
  GROUPS_METRICS,
  IMAGE_CONTENT_GROUP,
  durationFormatter,
} from "./utils";
import { numberFormatter } from "../../../utils/numberUtil";
import BaseLoading from "../../../components/BaseLoading";
import { KTSVG } from "../../../../_metronic/helpers";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import {
  systemMode,
  useThemeMode,
} from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";

const MonthlyAnalysisCard = () => {
  const intl = useIntl();
  const { mode } = useThemeMode();
  const isMountRef = useRef<boolean>();

  const { formattedData, loading } = useDashboardContext() as any;

  const [selectedGroup, setSelectedGroup] = useState<any>(
    GENERATED_CONTENT_GROUP
  );

  const _mode = useMemo(() => {
    return mode !== "system" ? mode : systemMode;
  }, [mode]);

  const getSelectedGroupChartSeries = () => {
    const { formatMessage: fm } = intl;

    return GROUPS_METRICS[selectedGroup].map((item: any) => {
      return {
        name: fm({ id: item.intlId }),
        data: _get(formattedData, item.dataPath, []),
      };
    });
  };

  const calculateSummaryAndAvg = (values: any) => {
    const summary = values.reduce((acc: any, curr: any) => {
      acc += curr;

      return acc;
    }, 0);

    return {
      summary,
      avg: summary / values.length,
    };
  };

  const chartOptions = useMemo(() => {
    const series = getSelectedGroupChartSeries();
    const categories = formattedData?.[selectedGroup]?.categories || [];

    return {
      series,
      chart: {
        height: 350,
        type: "area",
      },
      colors: ["#008FFB", "#00E396", "#775DD0"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        borderColor: getCSSVariableValue("--bs-text-gray-300"),
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        color: "red",
        markers: {
          width: 15,
          height: 2.5,
          radius: 0,
          offsetY: -3,
          offsetX: -4,
        },
        itemMargin: {
          horizontal: 8,
          vertical: 0,
        },
      },
      xaxis: {
        type: "datetime",
        categories,
        labels: {
          style: {
            colors: getCSSVariableValue("--bs-text-gray-800"),
          },
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        theme: _mode,
        x: {
          format: "dd/MM/yy",
        },
        y: [
          {
            title: {
              formatter: (seriesName: any) => `${seriesName}:`,
            } as any,
          },
          {
            title: {
              formatter: (seriesName: any) => `${seriesName}:`,
            },
            formatter: function (value: any) {
              return durationFormatter(value, intl);
            },
          },
          {
            title: {
              formatter: (seriesName: any) => `${seriesName}:`,
            },
          },
        ],
      },
    };
  }, [selectedGroup, formattedData, _mode]);

  const cardsData = useMemo(() => {
    const monthlySummaryData: any = [];
    const dailyAvgData: any = [];

    GROUPS_METRICS[selectedGroup].forEach((item: any) => {
      const { dataPath, ...rest } = item;
      const { summary, avg } = calculateSummaryAndAvg(
        _get(formattedData, dataPath, [])
      );

      monthlySummaryData.push({
        ...rest,
        value: summary,
      });
      dailyAvgData.push({
        ...rest,
        value: avg,
      });
    });

    return [
      {
        type: "monthlySummary",
        data: monthlySummaryData,
      },
      {
        type: "dailyAvg",
        data: dailyAvgData,
      },
    ];
  }, [selectedGroup, formattedData]);

  useEffect(() => {
    isMountRef.current = true;
  }, []);

  const boxShadow =
    _mode === "dark"
      ? "rgb(140 138 138 / 5%) 0px 0.1rem 0.75rem 0.25rem"
      : "0 0.1rem 0.75rem 0.25rem rgba(0, 0, 0, 0.05)";

  return (
    <div className="card">
      <div className="card-body">
        <BaseLoading loading={loading}>
          {/* MARK: - Chart */}
          <Chart chartOptions={chartOptions} />

          {/* MARK: - Cards */}
          <div className="d-flex flex-center gap-8 mt-4">
            {/* MARK: - Group Card */}
            <div
              className="d-flex flex-column gap-6 p-6 rounded"
              style={{
                boxShadow,
              }}
            >
              {GROUPS.map((group: any) => {
                const { id, icon } = group;

                const withActive = id === selectedGroup ? "bg-gray-200" : "";
                return (
                  <div
                    key={id}
                    className={`d-flex gap-6 p-3 rounded cursor-pointer ${withActive}`}
                    onClick={() => {
                      setSelectedGroup(id);
                    }}
                  >
                    <span className="d-flex w-45px h-45px justify-content-center align-items-center rounded bg-gray-100">
                      <KTSVG
                        path={`/media/icons/${icon}`}
                        className="svg-icon-24px"
                      />
                    </span>
                    <div className="d-flex w-250px align-items-center justify-content-between">
                      <span className="fw-semibold fs-6 text-gray-800">
                        <FormattedMessage
                          id={`dashboard.statisticCard.group.${id}`}
                        />
                      </span>
                      <KTSVG
                        path="/media/icons/arrow-right.svg"
                        className="svg-icon-1 text-dark"
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            {/* MARK: - Statistic Cards */}
            <div className="d-flex flex-column gap-5 align-items-center justify-content-center">
              {cardsData.map((item) => {
                return (
                  <div
                    key={item.type}
                    className="p-6 rounded"
                    style={{
                      boxShadow,
                      width: 520,
                    }}
                  >
                    <div className="mb-6 fs-5 text-gray-800">
                      <FormattedMessage
                        id={`dashboard.statisticCard.title.${item.type}`}
                      />
                    </div>

                    <div
                      className="d-flex"
                      style={{
                        justifyContent:
                          selectedGroup === IMAGE_CONTENT_GROUP
                            ? "center"
                            : "unset",
                      }}
                    >
                      {item.data.map((d: any, index: any) => {
                        const { icon, formatter } = d;
                        return (
                          <div
                            key={index}
                            className="d-flex align-items-center gap-3 min-w-175px"
                          >
                            <span
                              className="d-flex w-45px h-45px justify-content-center align-items-center rounded"
                              style={{ backgroundColor: COLORS[index] }}
                            >
                              <KTSVG
                                path={`/media/icons/${icon}`}
                                className="svg-icon-24px text-gray-600"
                              />
                            </span>
                            <div className="d-flex flex-column">
                              <span className="text-gray-900 fs-6">
                                <CountUp
                                  duration={isMountRef.current ? 0.2 : 1.5}
                                  end={d.value}
                                  formattingFn={(val: any) => {
                                    return typeof formatter === "function"
                                      ? formatter(val, intl)
                                      : numberFormatter({ number: val });
                                  }}
                                />
                              </span>
                              <span className="text-gray-500">
                                <FormattedMessage id={d.intlId} />
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </BaseLoading>
      </div>
    </div>
  );
};

export default MonthlyAnalysisCard;
