/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Chart from "../../../components/Chart";
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import BaseLoading from "../../../components/BaseLoading";
import { useDashboardContext } from "../DashboardProvider";
import { getChartOptions } from "./utils";

const RemainingUsageCard: FC = () => {
  const intl = useIntl();
  const { mode } = useThemeMode();
  const { currentPackage, currentPackageLoading } = useDashboardContext();

  const { wordChartOptions, imageChartOptions } = useMemo(() => {
    const remainingWordPercent = currentPackage.period_word_limit
      ? Math.floor(
          (currentPackage.remaining_word_limit /
            currentPackage.period_word_limit) *
            100
        )
      : 0;

    const remainingImagePercent = currentPackage.period_image_limit
      ? Math.floor(
          (currentPackage.remaining_image_limit /
            currentPackage.period_image_limit) *
            100
        )
      : 0;

    return {
      wordChartOptions: getChartOptions(
        remainingWordPercent,
        intl.formatMessage({ id: "dashboard.wordLimit" })
      ),
      imageChartOptions: getChartOptions(
        remainingImagePercent,
        intl.formatMessage({ id: "dashboard.imageLimit" })
      ),
    };
  }, [mode, currentPackage]);

  return (
    <div className="card h-100">
      <div className="card-body pb-1">
        <BaseLoading loading={currentPackageLoading}>
          <div className="fs-2 fw-semibold text-gray-900 mb-5">
            <FormattedMessage id="dashboard.remainingUsage" />
          </div>

          <div className="d-flex justify-content-around">
            <div>
              <div className="d-flex gap-4">
                <Chart chartOptions={wordChartOptions} className="m-auto" />
                <div>
                  <div className="mb-2 text-gray-600 mt-3">
                    <FormattedMessage id="dashboard.reamingLimit" />
                  </div>
                  <div className="d-flex fs-3 text-gray-900 align-items-center">
                    {currentPackage.remaining_word_limit?.toLocaleString()}{" "}
                    <FormattedMessage id="dashboard.words" />
                  </div>

                  <div className="mb-2 text-gray-600 mt-6">
                    <FormattedMessage id="dashboard.totalLimit" />
                  </div>
                  <div className="d-flex fs-3 text-gray-900 align-items-center">
                    {currentPackage.period_word_limit?.toLocaleString()}{" "}
                    <FormattedMessage id="dashboard.words" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="w-1px h-160px mt-2 mb-6 bg-secondary"
              style={{ marginLeft: 12 }}
            />
            <div>
              <div className="d-flex gap-4">
                <Chart chartOptions={imageChartOptions} className="m-auto" />
                <div>
                  <div className="mb-2 text-gray-600 mt-3">
                    <FormattedMessage id="dashboard.reamingLimit" />
                  </div>
                  <div className="d-flex fs-3 text-gray-900 align-items-center">
                    <FormattedMessage
                      id="dashboard.image"
                      values={{ limit: currentPackage.remaining_image_limit }}
                    />
                  </div>

                  <div className="mb-2 text-gray-600 mt-6">
                    <FormattedMessage id="dashboard.totalLimit" />
                  </div>
                  <div className="d-flex fs-3 text-gray-900 align-items-center">
                    <FormattedMessage
                      id="dashboard.image"
                      values={{ limit: currentPackage.period_image_limit }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BaseLoading>
      </div>
    </div>
  );
};

export default RemainingUsageCard;
