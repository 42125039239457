import besyncHttpClient from "./httpClient";

const getJobDetail = ({ job_id }: any) => {
  return besyncHttpClient.get("/general/job_details", {
    params: {
      job_id,
    },
  });
};

const getJobList = ({ type, status= "saved" }: any = {}) => {
  return besyncHttpClient.get("/general/list_jobs", {
    params: {
      type,
      status
    },
  });
};

const updateJob = ({ job_id, content, title }: any) => {
  return besyncHttpClient.post("/general/update_job", {
    content,
    title,
    job_id,
  });
};

const deleteJob = ({ job_id }: any) => {
  return besyncHttpClient.delete("/general/delete_job", {
    params: {
      job_id,
    },
  });
};

const jobApi = {
  getJobDetail,
  getJobList,
  updateJob,
  deleteJob,
};

export default jobApi;
