import EditorJS from "@editorjs/editorjs";
import { EDITOR_TOOLS } from "./constants";

export const getTempEditor = () => {
  return new Promise((resolve) => {
    const tempEditorContainer = document.createElement("div");
    tempEditorContainer.id = "temp-editorjs";
    tempEditorContainer.style.display = "none";
    document.body.appendChild(tempEditorContainer);

    const editor = new EditorJS({
      holder: "temp-editorjs",
      tools: EDITOR_TOOLS,
      onReady: () => {
        resolve(editor);
      },
    });
  });
};

export const createTuneItem = ({ label, icon, isDisable, onActivate }) => {
  const itemContainer = document.createElement("div");
  itemContainer.classList.add("ce-popover-item");

  const iconElement = document.createElement("div");
  iconElement.classList.add("ce-popover-item__icon");
  iconElement.innerHTML = icon;

  const labelElement = document.createElement("div");
  labelElement.classList.add("ce-popover-item__title");
  labelElement.innerText = label;

  itemContainer.appendChild(iconElement);
  itemContainer.appendChild(labelElement);
  itemContainer.onclick = onActivate;

  if (isDisable) {
    itemContainer.classList.add("ce-popover-item--disabled");
  } else {
    itemContainer.classList.remove("ce-popover-item--disabled");
  }

  return itemContainer;
};
