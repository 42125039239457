import { useEffect, useRef } from "react";
import { Tab } from "bootstrap";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
  ScrollTopComponent,
  StickyComponent,
  ToggleComponent,
  SwapperComponent,
} from "../assets/ts/components";
import { ThemeModeComponent } from "../assets/ts/layout";

import { useLayout } from "./core";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

export let _intl: any;
export let navigateTo: any;

export function MasterInit() {
  const intl = useIntl();
  const { config } = useLayout();
  const isFirstRun = useRef(true);
  navigateTo = useNavigate();

  const pluginsInitialization = () => {
    isFirstRun.current = false;
    ThemeModeComponent.init();
    setTimeout(() => {
      ToggleComponent.bootstrap();
      ScrollTopComponent.bootstrap();
      DrawerComponent.bootstrap();
      StickyComponent.bootstrap();
      MenuComponent.bootstrap();
      ScrollComponent.bootstrap();
      SwapperComponent.bootstrap();
      document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
        Tab.getOrCreateInstance(tab);
      });
    }, 500);
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      pluginsInitialization();
    }
  }, [config]);

  useEffect(() => {
    _intl = intl;
  }, [intl]);

  return <></>;
}
