export const getPackageContents = (packageId: any) => {
  let intlIds;
  if (packageId === 2 || packageId === 4) {
    intlIds = [
      "subscription.package.content.growthGuru.1",
      "subscription.package.content.growthGuru.2",
      "subscription.package.content.growthGuru.3",
      "subscription.package.content.growthGuru.4",
      "subscription.package.content.growthGuru.5",
      "subscription.package.content.growthGuru.6",
      "subscription.package.content.growthGuru.7",
      "subscription.package.content.growthGuru.8",
      "subscription.package.content.growthGuru.9",
      "subscription.package.content.growthGuru.10",
      "subscription.package.content.growthGuru.11",
      "subscription.package.content.growthGuru.12",
    ];
  } else if (packageId === 3 || packageId === 5) {
    intlIds = [
      "subscription.package.content.unlimitedBrilliance.1",
      "subscription.package.content.unlimitedBrilliance.2",
      "subscription.package.content.unlimitedBrilliance.3",
      "subscription.package.content.unlimitedBrilliance.4",
      "subscription.package.content.unlimitedBrilliance.5",
      "subscription.package.content.unlimitedBrilliance.6",
      "subscription.package.content.unlimitedBrilliance.7",
      "subscription.package.content.unlimitedBrilliance.8",
      "subscription.package.content.unlimitedBrilliance.9",
      "subscription.package.content.unlimitedBrilliance.10",
      "subscription.package.content.unlimitedBrilliance.11",
      "subscription.package.content.unlimitedBrilliance.12",
      "subscription.package.content.unlimitedBrilliance.13",
      "subscription.package.content.unlimitedBrilliance.14",
    ];
  } else {
    intlIds = [
      "subscription.package.content.free.1",
      "subscription.package.content.free.2",
      "subscription.package.content.free.3",
      "subscription.package.content.free.4",
      "subscription.package.content.free.5",
      "subscription.package.content.free.6",
      "subscription.package.content.free.7",
      "subscription.package.content.free.8",
      "subscription.package.content.free.9",
    ];
  }

  return intlIds;
};
