/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useMemo, useRef, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { FormattedMessage, useIntl } from "react-intl";
import { KTSVG } from "../../../_metronic/helpers";
import { EditorSettings } from "../../components/Editor/EditorSettings";
import { countLetters, countWords } from "../../utils";
import { aiApi } from "../../api";
import { getUserPreferences } from "../auth";
import BaseLoading from "../../components/BaseLoading";
import { swalUtil } from "../../utils/swalUtil";

export const RephraseContent = () => {
  const copyButtonRef = useRef<any>();
  const intl = useIntl();

  const [isVisibleEditorSettings, setIsVisibleEditorSettings] = useState(false);
  const [text, setText] = useState("");
  const [rephrasedText, setRephrasedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const { wordCount, letterCount } = useMemo(() => {
    return {
      wordCount: countWords(text),
      letterCount: countLetters(text),
    };
  }, [text]);

  const rephraseHandler = async () => {
    try {
      if (text.length === 0) {
        return;
      }
      setRephrasedText("");
      setLoading(true);
      const preferences = getUserPreferences();
      const response = await aiApi.paraphrase({
        text,
        language: preferences.default_language,
      });

      const content = response.data.content;
      let i = 4;

      const nextLetter = () => {
        setRephrasedText(content.slice(0, i));
        if (i < content.length) {
          i += 4;
          setTimeout(() => {
            nextLetter();
          }, 10);
        } else {
          setLoading(false);
        }
      };
      nextLetter();
    } catch (error) {
      setLoading(false);
      swalUtil.showErrorMessage(error);
    }
  };

  const onChangeEditorSettingsVisibility = useCallback(() => {
    setIsVisibleEditorSettings((prev) => {
      return !prev;
    });
  }, []);

  const onCopyClickHandler = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(rephrasedText);
    setTimeout(() => {
      setIsCopied(false);
      copyButtonRef.current.blur();
    }, 3000);
  };

  return (
    <>
      <PageTitle>
        <FormattedMessage id="rephrase.title" />
      </PageTitle>
      <div className="card">
        <div className="card-body">
          <div className="d-flex gap-8 h-100">
            <div className="w-100">
              <textarea
                style={{ minHeight: "calc(100vh - 300px)" }}
                className="form-control form-control-flush resize-none"
                placeholder={intl.formatMessage({
                  id: "rephrase.input.placeholder",
                })}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />

              {text.length === 0 && (
                <div
                  className="position-absolute top-50"
                  style={{ left: "20%" }}
                >
                  <button
                    className="btn btn-outline btn-active-light-primary fw-bold w-125px d-flex justify-content-center"
                    onClick={async () => {
                      const _text = await navigator.clipboard.readText();
                      setText(_text);
                    }}
                  >
                    <div>
                      <KTSVG
                        path={"/media/icons/clipboard.svg"}
                        className="svg-icon-2"
                      />
                    </div>
                    <div style={{ marginTop: 2 }}>
                      <FormattedMessage id="common.paste" />
                    </div>
                  </button>
                </div>
              )}

              <div className="d-flex justify-content-between mb-n4">
                <div className="d-flex gap-4 fs-6  align-items-center">
                  <span className="text-gray-700">
                    <FormattedMessage
                      id="editor.wordCount"
                      values={{ wordCount }}
                    />
                  </span>

                  <span className="w-1px h-20px bg-gray-300" />

                  <span className="text-gray-700">
                    <FormattedMessage
                      id="editor.letterCount"
                      values={{ letterCount }}
                    />
                  </span>
                </div>
                <div className="d-flex gap-4">
                  <a
                    className="btn btn-sm btn-outline btn-outline-dashed border-hover-light d-flex align-items-center"
                    onClick={onChangeEditorSettingsVisibility}
                  >
                    <KTSVG path="/media/icons/cog.svg" className="svg-icon-7" />
                    <FormattedMessage id="common.settings" />
                  </a>
                  {isVisibleEditorSettings && (
                    <EditorSettings
                      style={{ marginTop: -130, marginLeft: -100 }}
                      closeEditorSettings={onChangeEditorSettingsVisibility}
                    />
                  )}

                  <button
                    className="btn btn-sm btn-primary"
                    onClick={rephraseHandler}
                    disabled={loading}
                  >
                    <KTSVG
                      path="/media/icons/refresh-2.svg"
                      className="svg-icon-2"
                    />
                    <FormattedMessage id="editor.rephrase" />
                  </button>
                </div>
              </div>
            </div>

            <div className="border my-n8" />

            <div
              className="w-100 h-100 position-relative"
              style={{
                minHeight: "calc(100vh - 275px)",
              }}
            >
              <BaseLoading
                loading={loading}
                tip={<FormattedMessage id="common.aiLoadingTip" />}
              >
                {rephrasedText === "" ? (
                  <div className="d-flex flex-column gap-4">
                    <div className="h-15px bg-gray-200 rounded w-75" />
                    <div className="h-15px bg-gray-200 rounded w-100" />
                    <div className="h-15px bg-gray-200 rounded w-50" />
                  </div>
                ) : (
                  <div>
                    <div
                      className="text-gray-800"
                      style={{
                        height: "calc(100vh - 300px)",
                        overflow: "auto",
                        fontSize: "1.1rem",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {rephrasedText}
                    </div>
                    <div className="d-flex justify-content-end mt-4 mb-n4">
                      <button
                        ref={copyButtonRef}
                        className="btn btn-sm btn-light btn-active-light-primary fw-bold w-125px"
                        onClick={onCopyClickHandler}
                      >
                        <KTSVG
                          path={
                            isCopied
                              ? "/media/icons/check-circle.svg"
                              : "/media/icons/copy.svg"
                          }
                          className="svg-icon-1"
                        />
                        {intl.formatMessage({
                          id: isCopied
                            ? "wordPressPlugin.licenseCode.copied"
                            : "wordPressPlugin.licenseCode.copy",
                        })}
                      </button>
                    </div>
                  </div>
                )}
              </BaseLoading>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
