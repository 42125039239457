/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import MySwal from "../../../components/MySwal";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import userApi from "../../../api/userApi";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";

const initialValues = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const ChangePassword: FC<any> = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(false);

  const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string()
      .min(8, "Minimum 8 characters")
      .max(16, "Maximum 16 symbols")
      .required("Password is required"),
    confirmNewPassword: Yup.string()
      .required("Password confirmation is required")
      .when("newPassword", {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "New Password and Confirm New Password didn't match"
        ),
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: async (values) => {
      if (loading) {
        return;
      }
      setLoading(true);
      setHasErrors(false);

      try {
        await userApi.changePassword(values.oldPassword, values.newPassword);
        MySwal.fire({
          icon: "success",
          title: intl.formatMessage({ id: "user.changePassword.success" }),
          didClose: () => {
            navigate("/dashboard");
          },
          confirmButtonText: intl.formatMessage({
            id: "common.close",
          }),
          confirmButtonColor: getCSSVariableValue("--bs-primary"),
        });
        setLoading(false);
      } catch {
        setHasErrors(true);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">
            <FormattedMessage id="user.changePassword" />
          </h1>
          {/* end::Title */}
        </div>

        {/* begin::Error */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              <FormattedMessage id="user.changePassword.error" />
            </div>
          </div>
        )}
        {/* end::Error */}

        {/* begin::Form group Old password */}
        <div className="fv-row mb-10">
          <label className="form-label fw-bold text-dark fs-6">
            <FormattedMessage id="user.changePassword.oldPassword" />
          </label>
          <input
            type="password"
            placeholder={intl.formatMessage({
              id: "user.changePassword.oldPassword",
            })}
            autoComplete="off"
            {...formik.getFieldProps("oldPassword")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.oldPassword && formik.errors.oldPassword,
              },
              {
                "is-valid":
                  formik.touched.oldPassword && !formik.errors.oldPassword,
              }
            )}
          />
          {formik.touched.oldPassword && formik.errors.oldPassword && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.oldPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className="mb-10 fv-row" data-kt-password-meter="true">
          <div className="mb-1">
            <label className="form-label fw-bold text-dark fs-6">
              <FormattedMessage id="user.changePassword.newPassword" />
            </label>
            <div className="position-relative mb-3">
              <input
                type="password"
                placeholder={intl.formatMessage({
                  id: "user.changePassword.newPassword",
                })}
                autoComplete="off"
                {...formik.getFieldProps("newPassword")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.newPassword && formik.errors.newPassword,
                  },
                  {
                    "is-valid":
                      formik.touched.newPassword && !formik.errors.newPassword,
                  }
                )}
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.newPassword}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className="d-flex align-items-center mb-3"
              data-kt-password-meter-control="highlight"
            >
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className="text-muted">
            <FormattedMessage id="auth.passwordMeterDesc" />
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className="fv-row mb-10">
          <label className="form-label fw-bold text-dark fs-6">
            <FormattedMessage id="user.changePassword.confirmNewPassword" />
          </label>
          <input
            type="password"
            placeholder={intl.formatMessage({
              id: "user.changePassword.confirmNewPassword",
            })}
            autoComplete="off"
            {...formik.getFieldProps("confirmNewPassword")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.confirmNewPassword &&
                  formik.errors.confirmNewPassword,
              },
              {
                "is-valid":
                  formik.touched.confirmNewPassword &&
                  !formik.errors.confirmNewPassword,
              }
            )}
          />
          {formik.touched.confirmNewPassword &&
            formik.errors.confirmNewPassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.confirmNewPassword}</span>
                </div>
              </div>
            )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <Link to="/dashboard">
            <button
              type="button"
              className="btn btn-lg btn-light-primary fw-bold me-4"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <FormattedMessage id="common.cancel" />
            </button>
          </Link>
          <button type="submit" className="btn btn-lg btn-primary fw-bold">
            {!loading ? (
              <span className="indicator-label">
                <FormattedMessage id="common.save" />
              </span>
            ) : (
              <span className="indicator-loading">
                <FormattedMessage id="common.pleaseWait" />
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
};

export default ChangePassword;
