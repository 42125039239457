/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { DateTime } from "luxon";
import { KTSVG } from "../../../../_metronic/helpers";
import clsx from "clsx";

const ImageItem: FC<any> = ({
  onDeleteHandler,
  image,
  cardWidth,
  onDetailModalVisibilityHandler,
  setImageSelectionIds,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div
      style={{
        width: cardWidth,
        borderRadius: 8,
      }}
      className="generated-image-card position-relative cursor-pointer"
      onClick={() => {
        onDetailModalVisibilityHandler(true, image);
      }}
    >
      <div style={{ height: 330 }}>
        <img
          src={image.image_url}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: 8,
          }}
          alt=""
        />
      </div>

      <div
        className="image-action-icon position-absolute text-end top-0 py-2"
        style={{ right: 84 }}
      >
        <a
          className="btn btn-sm btn-light btn-active-primary p-2"
          href={image.image_url}
          onClick={(e) => {
            e.stopPropagation();
          }}
          download
        >
          <KTSVG path="/media/icons/download.svg" className="svg-icon-4 me-0" />
        </a>
      </div>

      <div
        className="image-action-icon position-absolute text-end top-0 py-2 px-2"
        style={{ right: 40 }}
      >
        <a
          className="btn btn-sm btn-light btn-active-light-danger p-2"
          onClick={(e) => {
            e.stopPropagation();
            onDeleteHandler(image.id);
          }}
        >
          <KTSVG path="/media/icons/delete.svg" className="svg-icon-4 me-0" />
        </a>
      </div>

      <div
        className={clsx(
          "position-absolute text-end top-0 end-0 py-2 px-2",
          isChecked ? "" : "image-action-icon"
        )}
        onClick={(e) => {
          e.stopPropagation();
          setIsChecked(!isChecked);
          setImageSelectionIds((prev: any) => {
            if (prev.includes(image.id)) {
              return prev.filter((id: any) => {
                return id !== image.id;
              });
            }
            return [...prev, image.id];
          });
        }}
      >
        <div
          className="form-check form-check-lg form-check-solid form-check-danger me-1 mt-1"
          style={{ marginRight: -6 }}
        >
          <input
            className="form-check-input"
            type="checkbox"
            name="delete"
            checked={true}
            disabled={!isChecked}
          />
        </div>
      </div>
      <div
        className="position-absolute bottom-0 left-0 w-100 py-5 text-end"
        style={{
          background: "rgba(0,0,0,.4)",
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        <span className="text-white me-4 fs-6 fw-semibold">
          {DateTime.fromISO(image.created_at).toRelative()}
        </span>
      </div>
    </div>
  );
};

export default ImageItem;
